.ap-filter-button {
  margin: 0 0 0 auto;
  padding: .5rem .75rem;
  line-height: 1.575;
  cursor: pointer;

  border: 1px solid $white;
  color: $white;
  &:hover {
    color: $anchor-color;
    border-color: $anchor-color;
  }

  @include breakpoint(small only) {
    display: inline-block;
    margin: 1rem auto 0 auto;
    font-size: .875rem;
  }

  font-size: .875rem;
}

.ap-filterset {
  display: none;
  &.active {
    display: block;
  }

  @include breakpoint(small only) {
    margin: 0;
    padding: 1rem 15px 1.25rem 15px;
    border-bottom: 1px solid $ap-border-color;
    background-color: $ap-layer-pri-color;
  }
  @include breakpoint(medium) {
    margin: -1px 0 1rem 0;
    padding: 1rem 1.5rem 1rem;
    background-color: $ap-layer-pri-color;
  }

  > form {
    margin: 0;
    padding: 0;
  }
}

.ap-filter {
  @include breakpoint(small only) {
  }
  @include breakpoint(medium) {
    display: flex;
    &.sts-vcenterd {
      align-items: center;
    }
  }

  margin: 0 0 1rem 0;
  padding: 0 0 .5rem 0;
  border-bottom: 1px solid darken($ap-border-color, 12%);
}

.ap-filter__name {
  @include breakpoint(small only) {
    margin: 0 0 .25rem 0;
    padding: 0;
  }
  @include breakpoint(medium) {
    flex: 0 0 8rem;
    margin: 0;
    padding: 0;
  }

  line-height: 1.8;
  width: 8rem;
  font-size: .875rem;
}

.ap-filter__items {
  // flex: 1;
  input {
    margin-bottom: .5rem;
  }

  > ul {
    display: flex;
    flex-wrap: wrap;

    margin: 0;
    padding: 0;
    list-style-type: none;

    > li {
      margin: 0 1.25rem .5rem 0;
      > input {
        margin-bottom: 0!important;
      }
    }
  }
}

.ap-filter-action {
  // display: flex;
  // justify-content: center;

  .button {
    margin-bottom: 0;

    & + .button {
      margin-left: 1.25rem;
    }
  }
}