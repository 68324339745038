.ap-wrap {
}

.ap-container {
  @include xy-grid-container;
}


// sticky footer
@include breakpoint(medium) {
  html, body {
    height: 100%;
  }

  body {
    display: flex;
    flex-direction: column;
  }

  .ap-wrap {
    flex: 1 0 auto;
  }
}