.ap-user-header {
  background-color: darken($body-background, 2%);
  background-image: url(../img/coverbg-b.png);
  background-repeat: repeat;

  @include breakpoint(small only) {
    margin: 0 0 2.5rem 0;
    padding: 2.5rem 0 1rem 0;
  }
  @include breakpoint(medium only) {
    margin: 0 0 2.5rem 0;
    padding: 2.5rem 0;
  }
  @include breakpoint(large) {
    margin: 0 0 3rem 0;
    padding: 3.75rem 0 3.75rem 0;
  }
}
.ap-user {
  position: relative;
  @include xy-grid-container($width: 700px);

  @include breakpoint(small only) {
    // display: flex;
    // align-items: center;

    // margin-top: 1.5rem;
    // margin-bottom: 2.5rem;
  }
  @include breakpoint(medium only) {
    display: flex;
    align-items: center;
    justify-content: center;

    // margin-top: 3rem;
    // margin-bottom: 3rem;
  }
  @include breakpoint(large) {
    display: flex;
    align-items: center;
    justify-content: center;

    // margin-top: 4.5rem;
    // margin-bottom: 3.75rem;
  }
}

  .ap-user__photo {
    display: block;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(small only) {
      margin: 0 auto 1rem auto;
      width: 90px;
      height: 90px;
      border-radius: 45px;
    }
    @include breakpoint(medium only) {
      margin: 0 30px 0 0;
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }
    @include breakpoint(large) {
      margin: 0 30px 0 0;
      width: 120px;
      height: 120px;
      border-radius: 60px;
    }
  }

  .ap-user__name {
    @include breakpoint(small only) {
      margin: .125rem 0 1.5rem 0;
      font-size: 1rem;
      font-weight: 800;
      text-align: center;
      // border-bottom: 1px dashed $ap-border-color;
    }
    @include breakpoint(medium only) {
      margin: 0 0 1.125rem 0;
      max-width: 300px;
      font-size: 1.25rem;
      font-weight: 800;
    }
    @include breakpoint(large) {
      margin: 0 0 1.125rem 0;
      max-width: 360px;
      font-size: 1.5rem;
      font-weight: 800;
    }
  }

  .ap-user__actions {
    display: flex;
    align-items: center;

    @include breakpoint(small only) {
      justify-content: center;

      margin: 1rem 0 1rem 0;
      padding: 0;
      line-height: 1.2;
      list-style-type: none;
      font-size: .875rem;

      > li {
        & + li {
          margin-left: 1.5rem;
        }
      }
    }
    @include breakpoint(medium) {
      margin: 0 0 .75rem 0;
      padding: 0;
      line-height: 1.2;
      list-style-type: none;
      font-size: .875rem;

      > li {
        & + li {
          margin-left: 1.25rem;
        }
      }
    }
    @include breakpoint(large) {
      margin-bottom: 1rem;
    }
  }

    .ap-user__actions__menu {
      @include breakpoint(small only) {
        position: absolute;
        top: 0;
        right: 15px;
      }
      @include breakpoint(medium) {
        position: relative;
      }

      > span {
        display: block;
        content: "";
        margin: 0;
        padding: 0;
        width: 32px;
        height: 32px;
        font-size: .8rem;

        color: $white;
        border-radius: 16px;
        border: 1px solid $white; //ap-border-color;
        background: transparent url(../img/actions.svg) no-repeat 50% 50% / 20px 5px;

        cursor: pointer;
        &:hover {
          background-color: $anchor-color;
        }
      }

      > ul {
        display: none;
        &.active {
          display: block;
        }

        position: absolute;
        @include breakpoint(small only) {
          top: 2px;
          right: 2px;
        }
        @include breakpoint(medium) {
          top: 2px;
          left: 2px;
        }

        margin: 0;
        padding: 0;
        list-style-type: none;
        width: 10rem;
        background-color: $ap-layer-2nd-color;
        border-radius: 2px;
        border: 1px solid $ap-border-color;
        overflow: hidden;

        > li {
          font-size: .875rem;

          // border-top: 1px solid #282840;
          > a {
            display: block;
            padding: .675rem .75rem;
            color: $white;
            &:hover {
              background-color: $anchor-color;
            }
          }
          > form {
            > button {
              display: block;
              padding: .675rem .75rem;
              width: 100%;
              line-height: 1.5;
              text-align: left;

              background: none;
              border: 0;
              color: $white;
              cursor: pointer;

              &:hover {
                background-color: $anchor-color;
              }
            }
          }
        }
      }
    }


.ap-user-content {
  display: none;

  &.active {
    display: block;
  }
}

/* @group buttons */

.ap-button--follow {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: .375rem .75rem .3rem .75rem;
  font-size: .8rem;
  // text-align: center;

  color: $white;
  border: 0;
  border-radius: 2px;
  background-color: $ap-layer-2nd-color;

  cursor: pointer;

  &:before {
    display: block;
    content: "";
    margin: 0 .25rem 3px 0;
    width: 14px;
    height: 16px;
    background: transparent url(../img/follow.svg) no-repeat 0 0 / 14px 32px;
  }

  &:hover {
    color: $white;
    background-color: $anchor-color;

    &:before {
      // background-position: 0 -16px;
    }
  }
}

.ap-button--message {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: .375rem .75rem .2rem .75rem;
  font-size: .8rem;
  // text-align: center;

  color: $white;
  // border: 1px solid $white;
  border-radius: 2px;
  background-color: $ap-border-color;

  &:before {
    display: block;
    content: "";
    margin: 0 .25rem 3px 0;
    width: 15px;
    height: 13px;
    background: transparent url(../img/message.svg) no-repeat 50% 100% / contain;
  }

  &:hover {
    color: $white;
    // border-color: $anchor-color;
    background-color: $anchor-color;
  }
}

.ap-button--others {
  display: block;
  width: 32px;
  height: 8px;

  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;

  background: transparent url(../img/others.svg) no-repeat 0 0 / 32px 16px;
  &:hover {
    background-position: 0 -8px;
  }
}

/* @end group buttons */


/* @group about */

.ap-user-about {
  @include xy-grid-container($width: 960px);
  // @include xy-gutters($gutters: map-merge($grid-margin-gutters, (medium: 0)));

  @include breakpoint(small only) {
    margin-top: 1.5rem;
  }
  @include breakpoint(medium) {
    margin-top: 1.5rem;
  }
  @include breakpoint(large) {
    display: flex;

    margin-top: 2rem;
    max-width: 960px;
  }
}

.ap-user-about__heading {
  margin: 0 0 1.125rem 0;
  padding: 0;
  font-size: 1rem;
  font-weight: bold;
}

.ap-user-about__location {
  display: inline-flex;
  align-items: center;

  &:before {
    display: block;
    content: '';
    margin: 0 .65rem 0 0;
    width: 15px;
    height: 18px;
    background: transparent url(../img/location.svg) no-repeat 50% / contain;
  }
}

.ap-user-about__registered {
  display: inline-flex;
  align-items: center;

  &:before {
    display: block;
    content: '';
    margin: 0 .65rem 0 0;
    width: 16px;
    height: 16px;
    background: transparent url(../img/calendar.svg) no-repeat 50% / contain;
  }
}

.ap-user-about-main {
  order: 0;
  flex: 1;
  word-break: break-all;
}

.ap-user-about-sub {
  @include breakpoint(small only) {
  }
  @include breakpoint(medium only) {
    display: flex;
    margin: 0 0 1.5rem 0;
    padding: 0 0 1.5rem 0;
    border-bottom: 1px solid $ap-border-color;
  }
  @include breakpoint(large) {
    order: 1;
    flex: 0 0 350px;
    margin: 0 0 0 30px;
  }
}

.ap-user-about-content {
  line-height: 1.4;
  word-break: break-all;

  @include breakpoint(small only) {
    // padding: 1.25rem;
  }
  @include breakpoint(medium only) {
    // padding: 1.25rem;
    // min-height: 5rem;
  }
  @include breakpoint(large) {
    padding: 1.5rem;
    min-height: 360px;
    background-color: lighten($body-background, 2%);
  }

  p {
    margin: 0 0 .5rem 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.ap-user-about-block {
  line-height: 1.4;
  word-break: break-all;

  @include breakpoint(small only) {
    margin: 0 0 1.25rem 0;
    padding: 0 0 1.25rem 0;
    border-bottom: 1px solid $ap-border-color;
  }
  @include breakpoint(medium only) {
    width: 50%;
  }
  @include breakpoint(large) {
    margin: 1.25rem 0 0 0;
    padding: 1.25rem 0 0 0;
    border-top: 1px solid $ap-border-color;

    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
    }
  }

  p {
    margin: 0 0 .5rem 0;
  }

  > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    > li {
      & + li {
        margin-top: .5rem;
      }
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.ap-user__network {
  margin: 0;
  padding: 0;
  list-style-type: none;

  > li {
    & + li {
      margin-top: .5rem;
    }
    > a {
      > small {
        font-size: .875rem;
        color: $body-font-color;
      }

      &:hover {
        color: $anchor-color-hover;
        > small {
          color: $anchor-color-hover;
        }
      }
    }
  }
}

/* @end group about */


.ap-user--compact {
  position: relative;
  @include xy-grid-container($width: 700px);

  @include breakpoint(small only) {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 1.75rem;
    margin-bottom: 1.5rem;
  }
  @include breakpoint(medium only) {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 1.75rem;
    margin-bottom: 1.5rem;
  }
  @include breakpoint(large) {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 3rem;
    margin-bottom: 2.5rem;
  }
}

  .ap-user__photo--compact {
    display: block;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(small only) {
      margin: 0 .75rem 0 0;
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
    @include breakpoint(medium only) {
      margin: 0 1rem 0 0;
      width: 50px;
      height: 50px;
      border-radius: 25px;
    }
    @include breakpoint(large) {
      margin: 0 1rem 0 0;
      width: 50px;
      height: 50px;
      border-radius: 25px;
    }
  }

  .ap-user__name--compact {
    @include breakpoint(small only) {
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
      // text-align: center;
      // border-bottom: 1px dashed $ap-border-color;
    }
    @include breakpoint(medium only) {
      margin: 0;
      max-width: 300px;
      font-size: 1.125rem;
      font-weight: bold;
    }
    @include breakpoint(large) {
      margin: 0;
      max-width: 360px;
      font-size: 1.125rem;
      font-weight: bold;
    }
  }


/* @group blocked */

.ap-user--blocked {
  @include breakpoint(small only) {
    margin: 5rem 0 0 0;
  }
  @include breakpoint(medium) {
    margin: 6.5rem 0 0 0;
  }
}
  .ap-user--blocked__status {
    @include xy-grid-container($width: 540px);
    text-align: center;

    > *:last-child {
      margin-bottom: 0;
    }
  }

/* @end group blocked */