.ap-works {
  @include xy-gutters($gutters: (small only: 0, medium only: 20px, large: 30px), $negative: true);

  @include breakpoint(small only) {
    // margin-top: .25rem;
  }
  @include breakpoint(medium) {
    display: flex;
    flex-wrap: wrap;
  }
  @include breakpoint(large) {
    // margin-top: .5rem;
  }
}

.ap-works__grid {
  display: block;

  @include breakpoint(small only) {
    border-bottom: 1px solid $ap-border-color;
  }
  @include breakpoint(medium only) {
    margin: 0 rem-calc(10px) 0 rem-calc(10px);
    width: calc(50% - 20px);
    &:nth-child(-n+2) {
      > .ap-work-item,
      > .ad-grid-item {
        border-top: 0;
      }
    }
  }
  @include breakpoint(large) {
    margin: 0 rem-calc(15px) 0 rem-calc(15px);
    width: calc(33.333% - 30px);
    &:nth-child(-n+3) {
      > .ap-work-item,
      > .ad-grid-item {
        border-top: 0;
      }
    }
  }
}

.ap-works-nav {
  margin: 0;
  padding: 0 0 .75rem 0;
  border-bottom: 1px solid $ap-border-color;

  > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    display: flex;
    align-items: center;
    justify-content: center;

    > li {
      & + li {
      }

      > a {
        // font-weight: 600;
        color: lighten($dark-gray, 10%);
        &.active,
        &:hover {
          font-weight: 600;
          color: $primary-color
        }
      }
    }

    @include breakpoint(small only) {
      // font-size: .875rem;

      > li {
        & + li {
          margin-left: 1.5rem;
        }

        > a {
          > b {
            font-size: 1rem;
          }
        }
      }
    }
    @include breakpoint(medium) {
      > li {
        & + li {
          margin-left: 2rem;
        }
      }
    }
    @include breakpoint(large) {
      // font-size: .875rem;

      > li {
        > a {
          > b {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

.ap-go-digging {
  display: block;
  background-color: $ap-layer-pri-color;
  text-align: center;

  @include breakpoint(small only) {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $ap-border-color;
  }
  @include breakpoint(medium) {
    margin: 2rem 0 0 0;
    padding: 2.5rem 0;
  }

  > strong {
    display: inline-block;
    font-weight: normal;

    @include breakpoint(small only) {
      padding: 0 0 1rem 0;
      font-size: 1rem;
      font-weight: bold;
      border-bottom: 2px solid $white;
    }
    @include breakpoint(medium) {
      padding: 0 0 1rem 0;
      font-size: 1.125rem;
      font-weight: bold;
      border-bottom: 2px solid $white;
    }
  }

  > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    @include breakpoint(medium) {
      display: flex;
      justify-content: center;
    }

    > li {
      @include breakpoint(small only) {
        font-size: .875rem;
        & + li {
          border-top: 1px solid $ap-layer-pri-border-color;
        }

        > a {
          display: block;
          padding: 1rem;

          color: $body-font-color;

          &:hover {
            color: $anchor-color;
            border-color: $anchor-color;
          }
        }
      }

      @include breakpoint(medium) {
        margin: 0;
        padding: 0 1rem;
        font-size: .875rem;
        font-weight: bold;

        > a {
          display: block;
          padding: .75rem;

          color: $body-font-color;
          border: 1px solid $ap-layer-pri-border-color;
          border-radius: 3px;

          &:hover {
            color: $anchor-color;
            border-color: $anchor-color;
          }
        }
      }
    }
  }
}

.ap-work-item {
  position: relative;
  height: 100%;

  @include breakpoint(small only) {
    margin: 0;
    padding: 1.25rem .875rem 1rem .875rem+rem-calc(40px)+.875rem;
    min-height: 1.125rem+rem-calc(40px)+1rem;
  }
  @include breakpoint(medium only) {
    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 1.5rem rem-calc(18px) 1.25rem rem-calc(18px)+rem-calc(45px);
    min-height: 1.2rem+rem-calc(45px)+1rem;
    border-top: 1px solid $ap-border-color;
  }
  @include breakpoint(large) {
    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 1.5rem 1rem 1.25rem 1rem+rem-calc(50px);
    min-height: 1.25rem+rem-calc(50px)+1rem;
    border-top: 1px solid $ap-border-color;
  }
}

.ap-work-item-header {
  @include breakpoint(small only) {
    margin: 0;
    padding: 0 0 .65rem 0;
  }
  @include breakpoint(medium only) {
    margin: 0 0 auto 0; //0 0 .35rem 0;
    padding: 0 0 .65rem 0;
  }
  @include breakpoint(large) {
    margin: 0 0 auto 0; //0 0 .5rem 0;
    padding: 0 0 .65rem 0;
  }
}
.ap-work-item-footer {
  display: flex;
  align-items: center;

  @include breakpoint(small only) {
    margin: .65rem 0 0 0;
    padding: 0;
  }
  @include breakpoint(medium) {
    margin: .65rem 0 0 0;
    padding: 0;
  }
}

.ap-work-item__play {
  position: absolute;
  display: block;
  content: "";
  background-repeat: no-repeat;

  &:not(.lyrics) {
    cursor: pointer;
    background-image: url(../img/play.svg);
    background-position: 0;
  }
  &.lyrics {
    background-image: url(../img/lyrics.svg);
    background-size: contain;
    background-position: 0;
  }

  @include breakpoint(small only) {
    left: .875rem;

    width: 40px;
    height: 40px;

    &:not(.lyrics) {
      background-size: 40px 120px;
      background-position: 0 -40px;

      &:hover {
        background-position: 0 -40px;
      }
      &.playing {
        background-position: 0 -80px;
      }
    }
  }
  @include breakpoint(medium only) {
    left: 0;//rem-calc(18px);
    width: 45px;
    height: 45px;

    &:not(.lyrics) {
      background-size: 45px 135px;
      background-position: 0 -45px;

      &:hover {
        background-position: 0 -45px;
      }
      &.playing {
        background-position: 0 -90px;
      }
    }
  }
  @include breakpoint(large) {
    left: 0;
    width: 50px;
    height: 50px;

    &:not(.lyrics) {
      background-size: 50px 150px;
      background-position: 0 -50px;

      &:hover {
        background-position: 0 -50px;
      }
      &.playing {
        background-position: 0 -100px;
      }
    }
  }
}

.ap-work-item__title {
  margin: 0;
  padding: 0;
  font-weight: bold;
  line-height: 1.4;

  @include breakpoint(small only) {
    font-size: 1rem;
  }
  @include breakpoint(medium only) {
    font-size: 1rem;
  }
  @include breakpoint(large) {
    font-size: 1rem;
  }

  > a {
    @include ap-anchor($color: $header-color);
  }
}

.ap-work-item__description {
  margin: .3rem 0 0 0;
  padding: 0;
  font-size: .875rem;
  color: $ap-inactive-text-color;
}

.ap-work-item__production {
  display: inline-block;
  font-weight: normal;
  color: $ap-subtext-color;

  @include breakpoint(small only) {
    font-size: .875rem;
    margin: .35rem 0 0 0;
  }
  @include breakpoint(medium only) {
    font-size: .875rem;
    margin: .35rem 0 0 0;
  }
  @include breakpoint(large) {
    font-size: .875rem;
    margin: .35rem 0 0 0;
  }
}
.ap-work-item__artist {
  display: inline-block;
  font-weight: normal;

  > small {
    color: $ap-subtext-color;
  }

  @include breakpoint(small only) {
    font-size: 1rem;
    margin: .35rem 0 0 0;
  }
  @include breakpoint(medium only) {
    font-size: 1rem;
    margin: .35rem 0 0 0;
  }
  @include breakpoint(large) {
    font-size: 1rem;
    margin: .35rem 0 0 0;
  }
}

.ap-work-item__flgs {
  margin: 0;
  padding: 0;
  list-style-type: none;
  line-height: 1.5;

  @include breakpoint(small only) {
    margin: 0 0 0 0;
  }
  @include breakpoint(medium only) {
    margin: 0 0 0 0;
  }
  @include breakpoint(large) {
    margin: 0 0 .15rem 0;
  }

  font-size: .875rem;
  color: $ap-subtext-color;

  > li {
    display: inline-block;
    // float: left;

    & + li {
      position: relative;
      margin: 0 0 0 .25rem;
      padding: 0 0 0 .675rem;
      &:before {
        position: absolute;
        left: 0;
        content: "/";
      }
    }
  }
}

.ap-work-item__meta {
  @include breakpoint(small only) {
    margin: 0;
    padding: 0;
  }
  @include breakpoint(medium) {
    margin: 0;
    padding: 0;
  }

  list-style-type: none;
  line-height: 1.5;
  font-size: .875rem;
  color: $ap-inactive-text-color;

  > li {
    display: inline;

    & + li {
      margin-left: .5rem;
    }

    > a {
      @include ap-anchor($color: $ap-username-color);
    }
  }
}

.ap-work-item__like {
  display: inline-flex;
  align-items: center;

  margin: 0 0 0 1rem;
  padding: 0;

  font-size: .935rem;

  &:before {
    content: '';
    display: inline-block;
    margin: 0 .5rem 0 0;
    width: 17px;
    height: 15px;
    background: transparent url(../img/like.svg) no-repeat 0 -30px / 17px 45px;
  }

  &:not(.sts-disabled).liked {
    &:before {
      background-position: 0 0;
    }
  }

  // color: $ap-subtext-color;
  color: $white;

  &:not(.sts-disabled) {
    cursor: pointer;

    &:before {
      // background-position: 0 -15px;
    }

    &:hover {
      color: $anchor-color;
      &:before {
        background-position: 0 -15px;
      }
    }
  }
}

.ap-work-item__played {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0 ;
  // line-height: 1;

  color: $ap-subtext-color;
  font-size: .935rem;

  &:before {
    content: '';
    display: inline-block;
    margin: 0 .5rem 0 0;
    width: 14px;
    height: 16px;
    background: transparent url(../img/played.svg) no-repeat 0 50% / 14px 16px;
  }
}

.ap-work-item__viewed {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0;
  // line-height: 1;

  color: $ap-subtext-color;
  font-size: .935rem;

  &:before {
    content: '';
    display: inline-block;
    margin: 0 .5rem 0 0;
    width: 20px;
    height: 13px;
    background: transparent url(../img/viewed.svg) no-repeat 0 50% / 20px 13px;
  }
}

.ap-work-item__comments {
  display: inline-flex;
  align-items: center;
  margin: 0 0 0 1rem;
  padding: 0 ;
  // line-height: 1;

  color: $ap-subtext-color;
  font-size: .935rem;

  &:before {
    content: '';
    display: inline-block;
    margin: 0 .4rem 0 0;
    width: 15px;
    height: 16px;
    background: transparent url(../img/comment.svg) no-repeat 0 50% / 15px 16px;
  }
}