.ap-page {
  @include xy-grid-container($width: 720px);
  color: $ap-text-color;

  @include breakpoint(small only) {
    margin-top: 3rem;
    margin-bottom: 2.5rem;

    > h2 {
      margin: 2.25rem 0 1.25rem 0;
      padding: 0 0 .875rem 0;
      font-size: 1.125rem;
      font-weight: normal;
      border-bottom: 1px solid $ap-border-color;
      // color: $ap-subtext-color;
    }

    > h3 {
      margin: 1.75rem 0 1rem 0;
      font-size: 1rem;
      font-weight: bold;
      color: $ap-text-color;

      &:before {
        display: inline-block;
        content: '';
        margin-right: .5rem;
        width: .75rem;
        height: .75rem;
        background-color: $ap-text-color;
        // border-left: 5px solid $white;
      }
    }
  }
  @include breakpoint(medium only) {
    margin-top: 3rem;
    margin-bottom: 3rem;

    > h2 {
      margin: 2.5rem 0 1.5rem 0;
      padding: 0 0 .875rem 0;
      font-size: 1.25rem;
      font-weight: normal;
      border-bottom: 1px solid $ap-border-color;
      // color: $ap-subtext-color;
    }

    > h3 {
      margin: 1.75rem 0 1rem 0;
      font-size: 1rem;
      font-weight: bold;
      color: $ap-text-color;

      &:before {
        display: inline-block;
        content: '';
        margin-right: .5rem;
        width: .75rem;
        height: .75rem;
        background-color: $ap-text-color;
        // border-left: 5px solid $white;
      }
    }
  }
  @include breakpoint(large) {
    margin-top: 4.5rem;
    margin-bottom: 3.75rem;

    > h2 {
      margin: 2.5rem 0 1.5rem 0;
      padding: 0 0 .875rem 0;
      font-size: 1.25rem;
      font-weight: normal;
      border-bottom: 1px solid $ap-border-color;
      // color: $ap-subtext-color;
    }

    > h3 {
      margin: 1.75rem 0 1rem 0;
      font-size: 1rem;
      font-weight: bold;
      color: $ap-text-color;

      &:before {
        display: inline-block;
        content: '';
        margin-right: .5rem;
        width: .75rem;
        height: .75rem;
        background-color: $ap-text-color;
        // border-left: 5px solid $white;
      }
    }
  }
}

.ap-page__heading {
  // text-align: center;
  @include breakpoint(small only) {
    margin: 0 0 1.5rem 0;

    font-size: 1.25rem;
    font-weight: bold;
  }
  @include breakpoint(medium only) {
    margin: 0 0 1.875rem 0;

    font-size: 1.5rem;
    font-weight: normal;
  }
  @include breakpoint(large) {
    margin: 0 0 2rem 0;

    font-size: 1.5rem;
    font-weight: normal;
  }
}