.ap-player {
  // @include xy-grid-container($width: 100%);
  z-index: 10;

  display: flex;
  align-items: center;
  width: 100%;

  background: $ap-player-background-color;

  @include breakpoint(medium down) {
    position: sticky;
    top: 0;
    height: 70px;
  }
  @include breakpoint(large) {
    position: fixed;
    bottom: 0;
    height: 75px;
  }
}

.ap-player__cntl {
  position: relative;
  order: 0;
  display: flex;
  align-items: center;

  @include breakpoint(small only) {
    // flex: 1 0 calc(60% - 50px);
    flex: 1;
    margin: 0 8px 0 20px;
    // width: calc(60% - 30px);
  }
  @include breakpoint(medium only) {
    flex: 1 0 calc(65% - 33px);
    margin: 0 8px 0 25px;
    width: calc(65% - 33px);
  }
  @include breakpoint(large) {
    flex: 1 0 calc(55% - 25px);
    margin: 0 0 0 25px;
    width: calc(55% - 25px);
  }
}

  .ap-player__cntl__play {
    @include breakpoint(medium down) {
      flex: 0 0 18px;
      display: block;
      content: '';
      margin: 0 15px 0 0;
      padding: 0;
      width: 18px;
      height: 21px;

      background-image: url(../img/player-control.svg);
      background-repeat: no-repeat;
      background-size: 73px 21px;
      background-position: -56px 0;

      .active & {
        cursor: pointer;
        &.canplay {
          background-position: 0 0;
        }
        &.playing {
          background-position: -19px 0;
        }
      }
    }
    @include breakpoint(large) {
      flex: 0 0 21px;
      display: block;
      content: '';
      margin: 0 15px 0 0;
      padding: 0;
      width: 21px;
      height: 25px;

      background-image: url(../img/player-control.svg);
      background-repeat: no-repeat;
      background-size: 87px 25px;
      background-position: -66px 0;

      .active & {
        cursor: pointer;
        &.canplay {
          background-position: 0 0;
        }
        &.playing {
          background-position: -22px 0;
        }
      }
    }

  }

  .ap-player__cntl__bar {
    display: none;
    .active & {
      display: block;
    }

    // flex: 1 0 100%;
    position: relative;
    z-index: 10;
    width: 100%;
    height: 7px;
    background-color: $ap-player-bar-color;
    cursor: pointer;
  }
    .ap-player__cntl__loadingbar {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 11;

      display: block;
      margin: auto;
      width: 100%;
      content: '';

      > span {
        position: absolute;
        top: 0;
        bottom: 0;
        // left: 0;

        display: block;
        margin: auto 0;
        content: '';
        background-color: lighten($ap-player-bar-color, 15%);
      }
    }
    .ap-player__cntl__seekbar {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 12;

      display: block;
      margin: auto 0;
      content: '';
      background-color: $ap-player-bar-active-color;
    }

  .ap-player__cntl__time {
    display: none;
    .active & {
      display: inline-flex;
      justify-content: center;
    }
    margin: 0 0 0 15px;
    width: 6rem;
    font-size: .8rem;
  }
  .ap-player__cntl__time__cur {
    width: 40%;
    text-align: right;
  }
  .ap-player__cntl__time__divider {
    margin: 0 .25rem;
  }
  .ap-player__cntl__time__duration {
    width: 40%;
  }

  .ap-player__cntl__message {
    display: block;
    .active & {
      display: none;
    }

    font-size: .8rem;
    color: $medium-gray;
  }

.ap-player__info {
  display: none;
  .active & {
    display: block;
  }

  order: 2;
  line-height: 1.4;
  color: $ap-username-color;
  &:hover {
    color: $anchor-color;
  }

  @include breakpoint(small only) {
    margin: 1px 25px 0 0;
    font-size: .8rem;
  }
  @include breakpoint(medium only) {
    margin: 0 25px 0 8px;
    width: calc(35% - 33px);
    font-size: .875rem;
  }
  @include breakpoint(large) {
    margin: 0 25px;
    width: calc(30% - 50px);
    font-size: .875rem;
  }
}
  .ap-player__info__user {
    @include breakpoint(small only) {
      display: none;
    }
    @include breakpoint(medium) {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .ap-player__info__title {
    @include breakpoint(small only) {
      display: none;
    }
    @include breakpoint(medium) {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .ap-player__info__alt {
    @include breakpoint(small only) {
      display: block;
    }
    @include breakpoint(medium) {
      display: none;
    }
  }

.ap-player__volume {
  @include breakpoint(medium down) {
    display: none;
  }
  @include breakpoint(large) {
    display: none;
    .active & {
      display: flex;
    }

    order: 1;
    flex: 0 0 120px;
    align-items: center;
    margin: 0 25px;
    width: 170px-50px;
  }
}

  .ap-player__volume__button {
    flex: 0 0 20px;
    display: block;
    content: '';
    margin: 0 7px 0 0;
    padding: 0;
    width: 20px;
    height: 16px;
    cursor: poiner;

    background: transparent url(../img/player-control.svg) no-repeat -44px 0 / 87px 25px;
  }

  .ap-player__volume__meter {
    position: relative;
    width: 100%;
    height: 7px;
    background-color: $ap-player-bar-color;
    cursor: pointer!important;
  }
  .ap-player__volume__bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    display: block;
    margin: auto 0;
    content: '';
    background-color: $ap-player-bar-active-color;
    cursor: pointer!important;
  }