$ap-topbar-height: 60px!default;
$ap-topbar-background-color: #0e0e1f!default;
$ap-topbar-border-color: #282840!default;

.ap-topbar {
  position: relative;
  z-index: 99;
  margin: 0;
  padding: 0;
  background-color: $ap-topbar-background-color;
}
  .ap-topbar__content {
    @include xy-grid-container;

    @include breakpoint(medium down) {
      position: relative;
      z-index: 999;
      height: $ap-topbar-height;
    }
    @include breakpoint(large) {
      display: flex;
      align-items: center;
      height: $ap-topbar-height;
    }
  }

.ap-name {
  padding: 0;
  overflow: hidden;
  > a {
    display: block;
    > img {
      display: block;
    }
  }

  @include breakpoint(medium down) {
    padding: 15px 0;
    width: 100%;
    > a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      display: block;
      margin: auto;
      width: 59px;
      height: 33px;
      // width: 124px;
      // height: 18px;
      > img {
        display: block;
        width: 100%;
      }
    }
  }
  @include breakpoint(large) {
    margin: 0;
    > a {
      display: block;
      margin: 0 auto;
      width: 59px;
      height: 33px;
      > img {
        display: block;
        width: 100%;
      }
    }
  }
}


/* @group nav container */

.ap-nav-icon {
  @include breakpoint(medium down) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;

    line-height: 1.2;
    display: block;
    margin: auto 0;
    padding: 0;

    width: 24px;
    height: 17px;

    text-align: center;
    color: $dark-gray;
    font-size: .6rem;
    // pointer-events: auto;
    cursor: pointer;

    &:not(.active) {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;

        width: 24px;
        height: 17px;

        // background: linear-gradient($primary-color 3px, transparent 3px, transparent 7px, $primary-color 7px, $primary-color 10px, transparent 10px, transparent 14px, $primary-color 14px);
        background: linear-gradient($white 3px, transparent 3px, transparent 7px, $white 7px, $white 10px, transparent 10px, transparent 14px, $white 14px);
      }
    }

    &.active {
      // position: relative;
      // display: inline-block;

      width: 24px;
      height: 24px;
      overflow: hidden;

      &::before, &::after {
        content: '';
        position: absolute;
        height: 4px;
        width: 100%;
        top: 50%;
        left: 0;
        margin: auto;
        background: $white;//$primary-color;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
  @include breakpoint(large) {
    display: none;
  }
}

.ap-nav {
  color: $white;
  line-height: 1.6;

  @include breakpoint(medium down) {
    display: none;
    &.active {
      display: block;
    }

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 200;

    margin: 0 auto 0 auto;
    padding: 1.5rem 0 0 0;
    background-color: $ap-topbar-background-color;
    // filter: drop-shadow(0 3px 2px rgba(153, 153, 153, .3));
    border-bottom: 1px solid $ap-topbar-border-color;
  }
  @include breakpoint(medium) {
    font-size: .875rem;
  }
  @include breakpoint(large) {
    flex: 1;
    display: flex;
    align-items: center;

    margin: 0 0 0 4rem;
  }
}

.ap-nav-top {
  @include breakpoint(medium down) {
    display: block;
    margin: -.25rem 0 1.25rem 0;
    padding: 0;
    line-height: 1.2;
    text-align: center;
  }

  > a {
    color: $white;
    &:hover {
      color: $anchor-color;
    }
  }
}

/* @end group nav container */


/* @group content menu */

.ap-nav-main {
  padding: 0;
  list-style-type: none;
  line-height: 1.6;

  @include breakpoint(medium down) {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    margin: 0;

    > li {
      padding: 0;

      > a {
        display: block;
        margin: 0;
        padding: 1.25rem .5rem;
        line-height: 1.2;
        color: $white;
        border-top: 1px solid $ap-topbar-border-color;
        text-align: center;
        &:hover {
          color: $anchor-color;
        }
      }
    }
  }
  @include breakpoint(large) {
    display: flex;
    align-items: center;

    margin: 0 auto 0 0;

    > li {
      // & + li {
        position: relative;
        margin: 0 0 0 1.25rem;
        padding: 0 0 0 1.25rem;
        &:before {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;

          content: "";
          margin: auto 0;
          width: 1px;
          height: .8rem;
          background-color: $ap-border-color;
        }
      // }

      > a {
        // line-height: 1;
        color: $white;
        // color: darken($dark-gray, 10%);
        // font-weight: 600;
        &:hover {
          color: $anchor-color;
        }
      }
    }
  }
}

/* @end group content menu */


/* @group 新規投稿DDL */

.ap-newpostddl {
  @include breakpoint(medium down) {
    margin: 0 15px;
    padding: 1.5rem 0 1.75rem 0;
    border-top: 1px solid $ap-topbar-border-color;
    border-bottom: 1px solid $ap-topbar-border-color;
  }
  @include breakpoint(medium) {
    font-size: .875rem;
  }
  @include breakpoint(large) {
    order: 0;
    position: relative;
    margin: 0;
  }
}
.ap-newpostddl__button {
  @include breakpoint(medium down) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 1rem 0;
    padding: 0;
    color: $white;
    cursor: pointer;

    > span {
      // margin: 0 1rem 0 0;
    }

    // .active > & {
    //   padding-bottom: 1rem;
    //   color: $ap-inactive-text-color;

    //   &:after {
    //     margin: 0 0 -2px 0;
    //     transform: rotate(-135deg);
    //   }
    // }
  }
  @include breakpoint(large) {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 1.5rem;
    height: $ap-topbar-height;
    border-left: 1px solid $ap-topbar-border-color;
  }
}
.ap-newpostddl__sub {
  line-height: 1.6;
  @include breakpoint(medium down) {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  @include breakpoint(large) {
    display: none;
    .active > & {
      display: block;
    }

    position: absolute;
    top: $ap-topbar-height;
    right: 0;

    margin: 0;
    padding: 1.5rem 0;
    width: 13rem;
    border-top: 1px solid $ap-topbar-border-color;
    background-color: $ap-topbar-background-color;

    &:before {
      position: absolute;
      top: -6px;
      right: 2rem;

      content: "";

      margin: 0;
      padding: 5px;

      border: solid $ap-topbar-border-color;
      border-width: 0 1px 1px 0;
      background-color: $ap-topbar-background-color;
      display: inline-block;

      width: 0;
      height: 0;

      transform: rotate(-135deg);
    }
  }
}
  .ap-newpostddl__sub__nav {
    margin: 0;
    padding: 0;
    list-style-type: none;

    > li {
      > a {
        color: $white;
      }
    }

    @include breakpoint(medium down) {
      display: flex;
      align-items: center;
      justify-content: center;

      > li {
        padding: 0 1.5rem;
        & + li {
          border-left: 1px solid $ap-border-color;
        }

        > a {
          display: block;
          padding: .125rem 0;
          line-height: 1.2;
          color: $white;
        }
      }
    }
    @include breakpoint(large) {
      > li {
        &:first-child {
          // border-color: $white;
        }
        > a {
          display: block;
          padding: .35rem 1.5rem;
          color: $white;
          &:hover {
            color: $anchor-color;
            background-color: $ap-layer-2nd-color;
          }
        }
      }
    }
  }

/* @end group 新規投稿DDL */


/* @group アカウントDDL */

.ap-accountddl-icon {
  @include breakpoint(medium down) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;

    content: "";
    line-height: 1.2;
    display: block;
    margin: auto 0;
    padding: 0;

    width: 34px;
    height: 34px;
    border-radius: 17px;

    text-align: center;
    color: $dark-gray;
    font-size: .6rem;
    // pointer-events: auto;
    cursor: pointer;

    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
  @include breakpoint(large) {
    display: none;
  }
}

.ap-accountddl {
  @include breakpoint(medium down) {
    display: none;
    &.active {
      display: block;
    }

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 200;

    margin: 0 auto 0 auto;
    padding: 1.5rem 0 0 0;
    background-color: $ap-topbar-background-color;
    // filter: drop-shadow(0 3px 2px rgba(153, 153, 153, .3));
    border-bottom: 1px solid $ap-topbar-border-color;
  }
  @include breakpoint(medium) {
    font-size: .875rem;
  }
  @include breakpoint(large) {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
  }
}
.ap-accountddl__button {
  @include breakpoint(medium down) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 1.5rem 0;
    padding: 0;
    // color: #777799;
  }
  @include breakpoint(large) {
    position: relative;
    order: 1;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 1.5rem;

    height: $ap-topbar-height;
    border-left: 1px solid $ap-topbar-border-color;
  }
}
  .ap-accountddl__username {
    display: block;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include breakpoint(small only) {
      max-width: 400px;
    }
    @include breakpoint(medium only) {
      max-width: 500px;
      font-size: .8rem;
    }
    @include breakpoint(large) {
      width: 7rem;
      font-size: .8rem;
    }
  }
    .ap-accountddl__photo {
      flex: 0 0 34px;
      position: relative;
      display: block;
      margin: 0 .4rem 0 0;
      padding: 0;

      width: 34px;
      height: 34px;
      border-radius: 17px;

      overflow: hidden;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
    }

.ap-accountddl__sub {
  line-height: 1.6;
  @include breakpoint(medium down) {
    margin: 0;
    padding: 0;
  }
  @include breakpoint(large) {
    display: none;
    .active > & {
      display: block;
    }

    position: absolute;
    top: $ap-topbar-height;
    right: 0;

    margin: 0;
    padding: 1.25rem 0;
    width: 12rem;
    font-size: .875rem;
    border-top: 1px solid $ap-topbar-border-color;
    background-color: $ap-topbar-background-color;

    &:before {
      position: absolute;
      top: -6px;
      right: 2rem;

      content: "";

      margin: 0;
      padding: 5px;

      border: solid $ap-topbar-border-color;
      border-width: 0 1px 1px 0;
      background-color: $ap-topbar-background-color;
      display: inline-block;

      width: 0;
      height: 0;

      transform: rotate(-135deg);
    }
  }
}
  .ap-accountddl__sub__nav {
    list-style-type: none;

    @include breakpoint(medium down) {
      // display: flex;
      // justify-content: center;
      margin: 0;
      padding: 0;

      > li {
        // flex: 1;
        margin: 0;
        padding: 0 .75rem;
        border-bottom: 1px solid $ap-topbar-border-color;
        text-align: center;
        // & + li {
        //   border-left: 1px solid $ap-topbar-border-color;
        // }

        > a {
          display: block;
          margin: 0;
          padding: 1.25rem 0;
          color: $white;
          text-align: center;
        }
      }
    }
    @include breakpoint(large) {
      margin: 0 0 1.25rem 0;
      padding: 0;

      &:after {
        display: block;
        content: "";
        margin: 1.25rem auto 0 auto;
        width: 10rem;
        border-bottom: 1px solid $ap-topbar-border-color;
      }

      > li {
        // border-top: 1px solid #333355;
        > a {
          display: block;
          padding: .35rem 1.25rem;
          color: $white;
          &:hover {
            color: $anchor-color;
            background-color: $ap-layer-2nd-color;
          }
        }
      }
    }
  }
  .ap-accountddl__sub__signout {
    // font-size: .875rem;

    @include breakpoint(medium down) {
      margin: 0;
      padding: 1.5rem 0;
      text-align: center;
    }
    @include breakpoint(large) {
      margin: 0;
      padding: 0;

      > a {
        display: block;
        padding: .35rem 1.25rem;

        &:hover {
          color: $anchor-color;
          background-color: $ap-layer-2nd-color;
        }
      }
    }

    > a {
      color: $white;
    }
  }

/* @end group アカウントDDL */


/* @group for guest */

.ap-nav-signin {
  line-height: 1.2;
  > a {
    color: $white;
  }

  @include breakpoint(medium down) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;

    display: flex;
    align-items: center;
    margin: auto 0;
    padding: 0;
    font-size: .8rem;
  }
  @include breakpoint(large) {
    font-size: .875rem;
    > a {
      &:hover {
        color: $anchor-color;
      }
    }
  }
}