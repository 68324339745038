.frm-container {
  &:not(.narrow) {
    @include xy-grid-container($width: 800px);
  }
  &.narrow {
    @include xy-grid-container($width: 540px);
  }
}

.frm-row {
  @include breakpoint(small only) {
    margin: 0 0 1.125rem 0;
  }
  @include breakpoint(medium) {
    // display: flex;
    // align-items: flex-start;
    margin: 0 0 1.5rem 0;
  }
}

.frm-row--cols {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -15px;

  > .frm-row {
    margin-bottom: .75rem;
    margin-left: 15px;
    margin-right: 15px;

    @include breakpoint(small only) {
      flex: 0 1 calc(50% - 30px);
    }
    @include breakpoint(medium) {
      flex: 0 1 calc(50% - 30px);

      &.c3 {
        flex: 0 1 calc(33.333% - 30px);
      }
    }
  }
}

// .frm-row__label:not(legend),
// legend.frm-row__label > span {
.frm-row__label {
  @include breakpoint(small only) {
    margin: 0 0 .25rem 0;
    padding: 0;
  }
  @include breakpoint(medium) {
    // flex: 0 0 8.5rem;
    // margin: 0 1rem 0 0;
    // padding: .4rem 0 0 0;
    display: block;
    margin: 0 0 .25rem 0;
    padding: 0;
  }

  font-weight: normal;
  font-size: $form-label-font-size;

  > small {
    display: inline;
    font-weight: normal;
    font-size: .75rem;
    color: #999;
  }
}

.frm-required {
  color: $warning-color!important;
}

.frm-row__controls {
  @include breakpoint(small only) {

  }
  @include breakpoint(medium) {
    // flex-grow: 1;
  }

  > select {
    margin: 0;
    max-width: 15rem;
  }

  > input[type=text] {
    margin: 0;
  }

  > textarea {
    margin: 0;
    &::-webkit-resizer {
      display: none;
    }
  }
}

.frm-actions {
  margin: 3rem 0 0 0;
  padding: 0 0 0 0;
  // border-top: 1px solid $ap-border-color;
  // text-align: center;
}

.frm-submit {
  @include button;
  // font-size: 1rem;
  // padding: 1.125em 1.125em 1.125rem 1.125rem;
}

.frm-hpfield {
  display: none;
}

.frm-checkbox {
  display: inline-block;
  // vertical-align: baseline;
  position: relative;
  top: 2px;
  margin: 0 .35rem 0 0;
  width: 15px;
  height: 15px;
  // line-height: 1.8;
  cursor: pointer;
  user-select: none;

  > .frm-checkbox-mark {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    border: 1px solid darken($ap-border-color, 13%); //$medium-gray;
    border-radius: 1px;

    &:after {
      display: none;

      position: absolute;
      left: 4px;
      top: 2px;
      content: "";

      width: 5px;
      height: 7px;
      border: solid white;
      border-width: 0 2px 2px 0;

      transform: rotate(45deg);
    }
  }

  > input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .frm-checkbox-mark {
        background-color: $anchor-color;
        border-color: $anchor-color;

        &:after {
          display: block;
        }
      }
    }
  }

  &:hover {
    > input {
      ~ .frm-checkbox-mark {
        background-color: $light-gray;
      }
    }
  }
}

.frm-radios,
.frm-checkboxes {
  display: flex;
  flex-wrap: wrap;

  margin: 0 0 -.25rem 0;
  padding: 0;
  list-style-type: none;

  > li {
    margin: 0 1rem .25rem 0;
    > label {
      > input {
        margin-bottom: 0;
      }
    }
  }
}

.frm-help-text {
  margin: 0;
  font-size: rem-calc(13);
  color: $medium-gray;
}

.frm-instruction {
}

.frm-warning {
 margin: 0 0 1.5rem 0;
 font-size: rem-calc(13);
 color: $warning-color;
 > p {
  margin-bottom: .35rem;
 }
 > *:last-child {
  margin-bottom: 0;
 }
}

/* @group error message */

.frm-error {
  display: block;
  margin: .25rem 0 0 0;
  padding: 0;
  font-size: .875rem;
  font-weight: normal;
  color: $alert-color;

  + .frm-error {
    margin-left: .5rem;
  }

  .frm-form-row + & {
    margin-top: -.5rem;
    margin-bottom: 1rem;
  }
}

ul.frm-error {
  list-style-type: none;
}

/* @endgroup error message */


.frm-collaborators {
  margin: 2rem 0 0 0;

  > legend {
    color: $dark-gray;
  }
}

.frm-instruction {
  display: block;
  padding: 0;
  font-size: .875rem;

  @include breakpoint(small only) {
    margin: 0 0 1.125rem 0;
  }
  @include breakpoint(medium) {
    // display: flex;
    // align-items: flex-start;
    margin: 0 0 1.5rem 0;
  }
}

.frm-inform {
  display: block;
  margin: 0;
  padding: 1.75rem 0;

  > *:last-child {
    margin-bottom: 0;
  }
}