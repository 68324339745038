.ad-image {
  display: block;
  width: 100%;
}
.ad-bgimage-wrap {
  @include breakpoint(small only) {
    margin: 0 auto;
    max-width: 300px;
  }
}
.ad-bgimage {
  position: relative;
  margin: 0 0 1.125rem 0;
  padding: 0 0 56.25% 0;
  width: 100%;
  height: 0;
  overflow: hidden;

  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  > img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
  }

  a:hover > & {
    &:after {
      background-color: #ccc;
    }
  }
}
.ad-title {
  display: block;
  margin: .75rem 0 0 0;
  font-weight: normal;
  font-size: 1rem; //.875rem;
}
.ad-description {
  display: block;
  margin: .5rem 0 0 0;
  font-size: .875rem;

  .ad-title + & {
    margin-top: .25rem;
  }
}
.ad-gtag {
  display: block;
  font-size: 1rem;
  text-align: center;
}


/* @group index page */

.ad-top-insert {
  background-color: darken($body-background, 2%);
  @include breakpoint(small only) {
    margin: 2.5rem 0 0 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    // border-bottom: 1px solid $ap-border-color;
  }
  @include breakpoint(medium only) {
    margin: 2.5rem 0 0 0;
    padding-top: 2.5rem;
    padding-bottom: 2rem;
    // border-top: 1px solid $ap-border-color;
    // border-bottom: 1px solid $ap-border-color;
  }
  @include breakpoint(large) {
    margin: 4.5rem 0 0 0;
    padding-top: 3rem;
    padding-bottom: 2.75rem;
    // border-top: 1px solid $ap-border-color;
    // border-bottom: 1px solid $ap-border-color;
  }
}

.ad-index {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.ad-index-item {
  color: $body-font-color;
  font-size: .875rem;
  line-height: 1.4;
  width: 50%;
  max-width: 350px;

  @include breakpoint(medium down) {
    padding: 0 15px;
  }
  @include breakpoint(large) {
    padding: 0 30px;
  }
}

/* @end group index page */


/* @group grid */

.ad-grid-insert {
  background-color: darken($body-background, 2%);
  @include breakpoint(small only) {
    margin: 2rem 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    // border-bottom: 1px solid $ap-border-color;
  }
  @include breakpoint(medium only) {
    margin: 2rem 0;
    padding-top: 2.5rem;
    padding-bottom: 2rem;
    // border-top: 1px solid $ap-border-color;
    // border-bottom: 1px solid $ap-border-color;
  }
  @include breakpoint(large) {
    margin: 3rem 0;
    padding-top: 3rem;
    padding-bottom: 2.75rem;
    // border-top: 1px solid $ap-border-color;
    // border-bottom: 1px solid $ap-border-color;
  }
}

.ad-grid-item {
  color: $body-font-color;

  @include breakpoint(small only) {
    display: block;
    margin: 0;
    padding: 1.25rem 1rem 1rem;
  }
  @include breakpoint(medium only) {
    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 1.5rem .5rem 1.25rem;
    border-top: 1px solid $ap-border-color;
  }
  @include breakpoint(large) {
    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 1.5rem .5rem 1.25rem;
    border-top: 1px solid $ap-border-color;
  }
}

/* @end group grid */


/* @group rectangles  */

.ad-recs-wrap {
  @include xy-grid-container;
}

.ad-recs {
  @include xy-gutters($negative: true, $gutters: (medium down: 30px, large: 30px));

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include breakpoint(medium down) {

  }
}

.ad-recs4-item {
  display: block;
  word-break: break-word;
  color: $body-font-color;
  &:hover {
    color: $anchor-color;
  }

  @include breakpoint(small only) {
    display: flex;
    margin: 1.5rem 15px 0 15px;
    padding: 1.5rem 0 0 0;
    width: 100%;
    border-top: 1px solid $ap-layer-pri-border-color;

    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
    }

    .ad-bgimage-wrap {
      flex: 0 0 45%;
      margin: 0 1rem 0 0;
      width: 45%;

      > .ad-bgimage {
        margin-bottom: 0!important;
      }
    }
    .ad-title {
      margin: 0;
    }
  }
  @include breakpoint(medium only) {
    display: flex;
    margin: 1.5rem 15px 0 15px;
    padding: 1.5rem 0 0 0;
    width: 100%;
    border-top: 1px solid $ap-layer-pri-border-color;

    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
    }

    .ad-bgimage-wrap {
      flex: 0 0 30%;
      margin: 0 1.5rem 0 0;
      width: 30%;

      > .ad-bgimage {
        margin-bottom: 0!important;
      }
    }
    .ad-title {
      flex: 0 0 40%;
      margin: 0;
    }
  }
  @include breakpoint(large) {
    width: calc(25% - 30px);
    margin: 0 15px;
  }
}

.ad-recs2-item {
  color: $body-font-color;
  &:hover {
    // > * {
    //   color`
    // }
  }

  @include breakpoint(small only) {
    width: 70%;
    margin: 0 15px;

    &:nth-child(n+2) {
      margin-top: 1.875rem;
    }
  }
  @include breakpoint(medium) {
    width: calc(50% - 30px);
    margin: 0 15px;

    &:nth-child(n+3) {
      margin-top: 2rem;
    }
  }
}

/* @end group rectangles  */


/* @group footer */

.ad-footer {
  // max-width: 728px;

  @include breakpoint(small only) {
    margin: 4rem auto 0 auto;
  }
  @include breakpoint(medium) {
    margin: 5rem auto 0 auto;
  }
}

/* @end group footer */


/* @group work page */

.ad-work-insert {
  @include breakpoint(small only) {
    margin: 2rem 0 0 0;
    padding: 2rem 0 0 0;
    border-top: 1px solid $ap-border-color;
  }
  @include breakpoint(medium) {
    margin: 2.5rem 0 0 0;
    padding: 2.875rem 0 0 0;
    border-top: 1px solid $ap-border-color;
  }
}

.ad-box {
  display: block;
  // max-width: 336px;
  margin: 0 auto;
}

/* @end group work page */


/* @group user page */

.ad-user {
  display: block;
  margin: 0 auto 2.5rem auto;
  // max-width: 728px;
}

.ad-user-insert {
  background-color: darken($body-background, 2%);
  @include breakpoint(small only) {
    margin: 3rem 0 0 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    // border-bottom: 1px solid $ap-border-color;
  }
  @include breakpoint(medium only) {
    margin: 5rem 0 0 0;
    padding-top: 2.5rem;
    padding-bottom: 2rem;
    // border-top: 1px solid $ap-border-color;
    // border-bottom: 1px solid $ap-border-color;
  }
  @include breakpoint(large) {
    margin: 5rem 0 0 0;
    padding-top: 3rem;
    padding-bottom: 2.75rem;
    // border-top: 1px solid $ap-border-color;
    // border-bottom: 1px solid $ap-border-color;
  }
}

/* @end group user page */


.ad-exception {
 color: $body-font-color;
 &:hover {
   color: $anchor-color;
 }

 .ad-description {
    font-size: 1rem!important;
 }
}