.ap-users-grid {
  @include xy-gutters($gutters: (small only: 0, medium only: 20px, large: 30px), $negative: true);

  @include breakpoint(small only) {
    // margin-top: .25rem;
  }
  @include breakpoint(medium) {
    display: flex;
    flex-wrap: wrap;
  }
  @include breakpoint(large) {
    // margin-top: .5rem;
  }
}

.ap-users-grid__item {
  display: block;

  @include breakpoint(small only) {
    border-bottom: 1px solid $ap-border-color;
  }
  @include breakpoint(medium only) {
    margin: 0 rem-calc(10px) 0 rem-calc(10px);
    width: calc(50% - 20px);
    &:nth-child(-n+2) {
      > .ap-user--listitem {
        border-top: 0;
      }
    }
  }
  @include breakpoint(large) {
    margin: 0 rem-calc(15px) 0 rem-calc(15px);
    width: calc(50% - 30px);
    &:nth-child(-n+2) {
      > .ap-user--listitem {
        border-top: 0;
      }
    }
  }
}

.ap-user--listitem {
  position: relative;
  height: 100%;

  @include breakpoint(small only) {
    display: flex;
    align-items: center;

    margin: 0;
    padding: 1.25rem rem-calc(15px);
  }
  @include breakpoint(medium only) {
    display: flex;
    align-items: center;

    margin: 0;
    padding: 1.25rem 0 1.25rem 0;
    min-height: 1.2rem+rem-calc(45px)+1rem;
    border-top: 1px solid $ap-border-color;
  }
  @include breakpoint(large) {
    display: flex;
    align-items: center;

    margin: 0;
    padding: 1.5rem 0;
    min-height: 1.5rem+rem-calc(50px)+1rem;
    border-top: 1px solid $ap-border-color;
  }
}

  .ap-user--listitem-content {
    flex: 1 1 0;
    margin: 0 1rem 0 0;
    min-width: 0;
  }

  .ap-user--listitem__photo {
    display: block;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(small only) {
      margin: 0 .75rem 0 0;
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
    @include breakpoint(medium only) {
      margin: 0 .75rem 0 0;
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
    @include breakpoint(large) {
      margin: 0 1rem 0 0;
      width: 50px;
      height: 50px;
      border-radius: 25px;
    }
  }

  .ap-user--listitem__name {
    padding: 0;
    font-weight: normal;
    line-height: 1.3;

    @include breakpoint(small only) {
      margin: 0;
      font-size: 1rem;
    }
    @include breakpoint(medium only) {
      margin: 0 0 auto 0; //0 0 .35rem 0;
      font-size: .875rem;
    }
    @include breakpoint(large) {
      margin: 0 0 auto 0; //0 0 .5rem 0;
      font-size: .875rem;
    }

    > a {
      @include ap-anchor($color: $ap-username-color);
    }
  }

  .ap-user--listitem__description {
    margin: .5rem 0 0 0;
    font-size: .875rem;
    color: $ap-subtext-color;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ap-user-griditem__button {
    display: block;
    margin: 0;
    padding: .275rem .35rem .25rem .35rem;
    line-height: 1.4;
    min-width: 5.5rem;
    font-size: .7rem;
    text-align: center;
    color: $white;
    // border: 1px solid $white;
    border: 0;
    border-radius: 2px;
    background-color: $primary-color;
    cursor: pointer;

    &:hover {
      color: $white;
      // border-color: $anchor-color;
      background-color: $anchor-color;
    }

    &.sts-followed {
      color: $white;
      border: 0;
      background-color: $ap-layer-2nd-color;

      &:hover {
        color: $white;
        // border-color: $anchor-color;
        background-color: $anchor-color;
      }
    }
  }

.ap-users-index {
  @include xy-gutters($gutters: (small only: 0, medium only: 20px, large: 30px), $negative: true);

  @include breakpoint(small only) {
    margin-top: 1.25rem;
  }
  @include breakpoint(medium) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
  }
  @include breakpoint(large) {
    margin-top: 1rem;
  }
}

.ap-users-index__item {
  display: block;

  @include breakpoint(small only) {
    border-top: 1px solid $ap-border-color;

    > .ap-user--listitem {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @include breakpoint(medium only) {
    margin: 0 rem-calc(10px) 0 rem-calc(10px);
    width: calc(50% - 20px);
    &:nth-child(-n+2) {
      > .ap-user--listitem {
        border-top: 0;
      }
    }
  }
  @include breakpoint(large) {
    margin: 0 rem-calc(15px) 0 rem-calc(15px);
    width: calc(33.333% - 30px);
    &:nth-child(-n+3) {
      > .ap-user--listitem {
        border-top: 0;
      }
    }
  }
}