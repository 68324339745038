
.ap-work {
  @include breakpoint(small only) {
    // margin: 1.875rem 0 0 0;
  }
  @include breakpoint(medium only) {
    // margin: 2.5rem 0 0 0;
  }
  @include breakpoint(large) {
    // margin: 4.5rem 0 0 0;
  }
}
.ap-work-header-wrap {
  background-color: darken($body-background, 2%);
  background-image: url(../img/coverbg-b.png);
  background-repeat: repeat;

  @include breakpoint(small only) {
    margin: 0 0 1.875rem 0;
    padding: 1.875rem 0;
  }
  @include breakpoint(medium only) {
    margin: 0 0 2rem 0;
    padding: 2.5rem 0;
  }
  @include breakpoint(large) {
    margin: 0 0 3.5rem 0;
    padding: 4rem 0 4rem 0;
  }
}

.ap-work-header {

  @include xy-grid-container;

  @include breakpoint(small only) {
    // margin-bottom: 2.5rem;
  }
  @include breakpoint(medium only) {
    // margin-bottom: 2.5rem;
  }
  @include breakpoint(large) {
    display: flex;
    // margin-bottom: 4rem;
  }
}

.ap-work-header-content {
  position: relative;

  @include breakpoint(small only) {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
  @include breakpoint(medium only) {
    margin: 0;
    padding: 0 0 0 1.25rem+rem-calc(56px);
    min-height: rem-calc(56px);
  }
  @include breakpoint(large) {
    flex: 1;
    margin: 0;
    padding: 0 0 0 1.5rem+rem-calc(70px);
    min-height: rem-calc(70px);
  }
}
.ap-work-header-ad {
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(small only) {
    margin: 2rem 0 0 0;
  }
  @include breakpoint(medium only) {
    margin: 3rem 0 0 0;
  }
  @include breakpoint(large) {
    flex: 0 0 350px;
    margin: 0 0 0 50px;
  }
}

  .ap-work__play {
    cursor: pointer;

    display: block;
    content: "";
    background-repeat: no-repeat;
    background-position: 0 0;
    outline: 0;
    -webkit-tap-highlight-color: transparent;

    &:not(.lyrics) {
      background-image: url(../img/play.svg);

    }
    &.lyrics {
      background-image: url(../img/lyrics.svg);
      background-size: contain;
      cursor: auto;
    }

    @include breakpoint(small only) {
      margin: 0 1.25rem 0 0;
      flex: 0 0 56px;
      width: 56px;
      height: 56px;

      &:not(.lyrics) {
        background-size: 56px 168px;
        background-position: 0 -56px;

        &:hover {
          background-position: 0 -56px;
        }
        &.playing {
          background-position: 0 -112px;
        }
      }
    }
    @include breakpoint(medium only) {
      position: absolute;
      left: 0;
      width: 56px;
      height: 56px;

      &:not(.lyrics) {
        background-size: 56px 168px;
        background-position: 0 -56px;

        &:hover {
          background-position: 0 -56px;
        }
        &.playing {
          background-position: 0 -112px;
        }
      }
    }
    @include breakpoint(large) {
      position: absolute;
      left: 0;
      width: 70px;
      height: 70px;

      &:not(.lyrics) {
        background-size: 70px 210px;
        background-position: 0 -70px;

        &:hover {
          background-position: 0 -70px;
        }
        &.playing {
          background-position: 0 -140px;
        }
      }
    }
  }

  .ap-work__title {
    padding: 0;
    font-weight: 800;
    color: $header-color;

    @include breakpoint(small only) {
      order: 1;
      margin: .35rem 0 .35rem 0;
      line-height: 1.3;
      font-size: 1.4rem;
    }
    @include breakpoint(medium only) {
      margin: .5rem 0 .5rem 0;
      line-height: 1.25;
      font-size: 1.5rem;
    }
    @include breakpoint(large) {
      margin: .5rem 0 .5rem 0;
      line-height: 1.3;
      font-size: 1.5rem;
    }
  }
  .ap-work__production {
    color: $ap-subtext-color;

    @include breakpoint(small only) {
      font-size: .875rem;
      font-weight: normal;
    }
    @include breakpoint(medium only) {
      font-size: .875rem;
      font-weight: normal;
    }
    @include breakpoint(large) {
      font-size: .875rem;
      font-weight: normal;
    }
  }
  .ap-work__artist {
    > small {
      margin-left: .25rem;
      font-size: .875rem;
      color: $ap-subtext-color;
    }

    @include breakpoint(small only) {
      font-size: 1rem;
      font-weight: normal;
    }
    @include breakpoint(medium only) {
      font-size: 1.125rem;
      font-weight: normal;
    }
    @include breakpoint(large) {
      font-size: 1.125rem;
      font-weight: normal;
    }
  }
  .ap-work-item__sub {
    display: block;
    margin: .875rem 0 0 0;
    font-size: .875rem;
    > i {
      font-style: normal;
    }
  }


  .ap-work__user {

    color: $medium-gray;
    > span {
      margin: 0 .25rem;
    }
    > a {
      @include ap-anchor($color: $ap-username-color);
    }

    @include breakpoint(small only) {
      order: 0;
      font-size: .875rem;
    }
    @include breakpoint(medium) {
      font-size: 1rem;
    }
  }

  .ap-work__flgs {
    padding: 0;
    list-style-type: none;
    line-height: 1.5;

    @include breakpoint(small only) {
      margin: .5rem 0 0 0;
    }
    @include breakpoint(medium only) {
      margin: .5rem 0 0 0;
    }
    @include breakpoint(large) {
      margin: .875rem 0 0 0;
    }

    font-size: .875rem;
    color: $ap-subtext-color;

    > li {
      display: inline-block;
      // float: left;

      & + li {
        position: relative;
        margin: 0 0 0 .5rem;
        padding: 0 0 0 .85rem;
        &:before {
          position: absolute;
          left: 0;
          content: "/";
        }
      }
    }
  }

  .ap-work__meta {
    @include breakpoint(small only) {
      order: 3;
      margin: .35rem 0 0 0;
    }
    @include breakpoint(medium only) {
      margin: .25rem 0 0 0;
    }
    @include breakpoint(large) {
      margin: .25rem 0 0 0;
    }

    margin: 0;
    padding: 0;
    list-style-type: none;
    line-height: 1.5;
    font-size: .875rem;
    color: $ap-subtext-color;

    > li {
      display: inline;

      & + li {
        margin-left: .75rem;
      }

      > a {
        color: $body-font-color;
        &:hover {
          color: $anchor-color-hover;
        }
      }
    }
  }

  .ap-work__marks {
    display: flex;
    padding: 0;
    list-style-type: none;

    @include breakpoint(small only) {
      margin: 1rem 0 0 0;

      > li {
        & + li {
          margin-left: 1.5rem;
        }
      }
    }

    @include breakpoint(medium) {
      margin: 1.5rem 0 0 0;

      > li {
        & + li {
          margin-left: 1.75rem;
        }

        > a,
        > span {
        }
      }
    }
    @include breakpoint(large) {
      margin-left: auto;
    }
  }
    .ap-work__marks__like {
      display: inline-flex;
      align-items: flex-end;

      margin: 0;
      padding: 0;
      line-height: 1;
      width: auto;

      font-size: 1rem;

      &:before {
        content: "";
        margin: 0 .65rem 0 0;
        width: 26px;
        height: 22px;
        background: transparent url(../img/like.svg) no-repeat 0 -44px / 26px 66px;
      }

      &:not(.sts-disabled).liked {
        &:before {
          background-position: 0 0;
        }
      }

      &:not(.sts-disabled) {
        cursor: pointer;
        &:hover {
          // background-color: $anchor-color;
          color: $anchor-color;

          &:before {
            background-position: 0 -22px;
          }
        }
      }
    }
    .ap-work__marks__report {
      display: inline-flex;
      align-items: flex-end;
      margin: 0;
      padding: 0;
      line-height: 1.2;
      font-size: .8rem;
      color: $ap-inactive-text-color;

      &:before {
        flex: 1;
        display: block;
        content: "";
        margin: 0 .25rem 0 0;
        width: 19px;
        height: 22px;
        background: transparent url(../img/flag.svg) no-repeat 0 -22px / 19px 44px;
      }
      &:hover {
        color: $anchor-color;
        &:before {
          background-position: 0 0;
        }
      }
    }

/* @group body */

.ap-work-main {
  @include xy-grid-container; //($padding: map-merge($grid-margin-gutters, (medium down: 0)));
  @include breakpoint(large) {
    display: flex;
  }
}

  .ap-work__body {
    flex: 1;
    margin: 0;
    padding: 0 0 1.6rem 0;
  }

  .ap-work__body__content {
    display: none;
    &.active {
      display: block;
    }
  }

  .ap-work__aside {
    @include breakpoint(small only) {
      margin: 2rem 0 0 0;
      padding: 0 0 0 0;
      // border-top: 1px solid $ap-border-color;
    }
    @include breakpoint(medium only) {
      margin: 2rem 0 0 0;
      padding: 0 0 0 0;
      // border-top: 1px solid $ap-border-color;
    }
    @include breakpoint(large) {
      flex: 0 0 350px;
      width: 350px;
      margin: 0 0 0 50px;
    }
  }

  .ap-work__annotation {
    margin: 2rem 0 0 0;
    padding: 0;
    color: $ap-subtext-color;
    font-size: .875rem;

    > ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      > li {
        margin: .25rem 0;
      }
    }
  }

  .ap-work__body__content__alt-message {
    margin: 3rem 0 0 0;
    text-align: center;
  }

/* @end group body */


/* @group nav */

.ap-work-nav {
  border-bottom: 1px solid $ap-border-color;

  @include breakpoint(small only) {
    margin: 0 0 1.5rem 0;
  }
  @include breakpoint(medium) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0 0 1.5rem 0;
  }
  @include breakpoint(large) {
  }
}

  .ap-work__tabs {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;

    @include breakpoint(small only) {
      justify-content: center;
      font-size: .875rem;
    }
    @include breakpoint(medium only) {
    }

    > li {
      @include breakpoint(small only) {
        flex: 1;
      }

      & + li {
        margin-left: 3px;
      }

      > a {
        display: block;
        margin: 0;
        @include breakpoint(small only) {
          padding: 0 0 .5rem 0;
        }
        @include breakpoint(medium) {
          padding: 0 0 .5rem 0;
          width: 8rem;
        }

        text-align: center;
        color: $ap-inactive-text-color;
        border-bottom: 5px solid $ap-border-color;

        &:hover {
          color: $anchor-color;
        }
      }

      &.active {
        > a {
          color: $white;
          border-bottom: 5px solid $white;
        }
      }
    }
  }

/* @end group nav */


/* @group comments */

.ap-work__comments {
  @include breakpoint(small only) {
    margin: 2rem 0 0 0;
    padding: 2rem 0 0 0;
  }
  @include breakpoint(medium) {
    margin: 3rem 0 0 0;
    padding: 2.5rem 0 0 0;
  }

  border-top: 1px solid $ap-border-color;
}

.ap-work__comments__heading {
  margin: 0 0 2rem 0;
  font-size: 1rem;
  font-weight: 600;
}

/* @end group comments */

/* @group related works */

.ap-work__aside__heading {

  line-height: 1.6;

  font-size: 1rem;
  font-weight: bold;

  @include breakpoint(small only) {
    margin: 2rem 0 1.5rem 0;
    padding: 0;
  }
  @include breakpoint(medium only) {
    margin: 2rem 0 1.5rem 0;
    padding: 0;
  }
  @include breakpoint(large) {
    margin: 1.5rem 0 0 0;
    padding: 0 0 .5rem+rem-calc(5px) 0;
    border-bottom: 1px solid $ap-border-color;
  }

  &:first-child {
    margin-top: 0;
  }
}

.ap-recommended-works {
  @include breakpoint(small only) {
    border-top: 1px solid $ap-border-color;
  }
  @include breakpoint(medium only) {
    // display: flex;
    // flex-wrap: wrap;
  }
  @include breakpoint(large) {

  }
}

.ap-recommended-works__item {
  @include breakpoint(small only) {
    > .ap-work-item {
      margin: 0;
      padding: 1.25rem 0 .8rem rem-calc(40px)+.875rem;
      border-bottom: 1px solid $ap-border-color;

      > .ap-work-item__play {
        left: 0;
      }
    }
  }
  @include breakpoint(medium only) {
    > .ap-work-item {
      margin: 0;
      // padding: 1.25rem 0 1rem rem-calc(40px)+1rem;
      border-bottom: 1px solid $ap-border-color;

      > .ap-work-item__play {
        left: 0;
      }
    }
  }
  @include breakpoint(large) {
    > .ap-work-item {
      margin: 0;
      // padding: 1.25rem 0 .8rem rem-calc(50px)+1rem;
      border: 0;
      border-bottom: 1px solid $ap-border-color;

      > .ap-work-item__play {
        left: 0;
      }
    }
  }
}

.ap-work__more {
  display: block;
  margin: 1.5rem 0 0 0;
  padding: 1rem .75rem;

  font-size: .875rem;
  text-align: center;
  background-color: $ap-layer-pri-color;

  color: $body-font-color;
  &:hover {
    color: $anchor-color;
  }
}

/* @end group related works */


/* @group attached */

.ap-work-attached {
  @include breakpoint(small only) {
    margin: 0 0 1.5rem 0;
  }
  @include breakpoint(medium only) {
    margin: 0 0 1.5rem 0;
  }
  @include breakpoint(large) {
    margin: 0 0 1.5rem 0;
  }

  > .ap-work-item {
    padding-top: 0;
    border: 0;
    border-bottom: 1px solid $ap-border-color;

    @include breakpoint(small only) {
      // padding-bottom: 1.25rem;
    }
    @include breakpoint(medium only) {
      // padding-bottom: 1.5rem;
    }
    @include breakpoint(large) {
      // padding-bottom: 1.5rem;
    }
  }
}

/* @end group lyrics */

.ap-work-ads {
  @include breakpoint(small only) {
    margin: 0 auto 0 auto;
    padding: 0;
    max-width: 300px;
  }
  @include breakpoint(medium only) {
    display: flex;
    justify-content: center;
    margin: 1.5rem 0 0 0;
  }
  @include breakpoint(large) {
    margin: 0 auto;
    padding: 0;
    max-width: 300px;
  }
}
  .ap-work-ads-item {
    @include breakpoint(small only) {
      margin: 2rem 0;
    }
    @include breakpoint(medium only) {
      // flex: 1;
      margin: 0 15px;
      width: 300px;
    }
    @include breakpoint(large) {
       margin: 1.5rem 0;
    }
  }


.ap-work-share {
  display: flex;

  margin: 2rem 0 0 0;
  padding: 0;
  list-style-type: none;

  > li {
    & + li {
      margin: 0 0 0 1.5rem;
    }

    > a {
      display: block;
      width: 30px;
      height: 30px;

      text-indent: 100%;
      overflow: hidden;
      white-space: nowrap;

      background-image: url(../img/social-icons.png);
      background-repeat: no-repeat;
      background-size: 60px 30px;
    }
    &.ap-work-share__twitter {
      > a {
        background-position: 0 0;
      }
    }
    &.ap-work-share__facebook {
      > a {
        background-position: -30px 0;
      }
    }
  }
}