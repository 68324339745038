.ap-login {
  @include breakpoint(small only) {
    margin: 3rem auto 0 auto;
  }
  @include breakpoint(medium) {
    margin: 4rem auto 0 auto;
  }
}

.ap-login__header {
  text-align: center;
  @include breakpoint(small only) {
    margin: 0 auto 3rem auto;
  }
  @include breakpoint(medium) {
    margin: 0 auto 3rem auto;
  }
}
.ap-login__header__title {
  margin: 0;
  padding: 0;
}
.ap-login__header__logo {
  @include breakpoint(small only) {
    display: block;
    margin: 0 auto 1.5rem auto;
    width: 78px;
    height: 44px;
  }
  @include breakpoint(medium) {
    display: block;
    margin: 0 auto 1.5rem auto;
    width: 78px;
    height: 44px;
  }
}


.ap-login-form {
  @include breakpoint(small only) {
    margin: 0 auto 0 auto;
    width: 300px;
  }
  @include breakpoint(medium) {
    margin: 0 auto 0 auto;
    width: 360px;
  }

  label {
    display: block;
    margin: 0 0 1.5rem 0;
    padding: .25rem 0 0 0;
    line-height: 1;
    background-color: $ap-layer-2nd-color;
    color: $medium-gray;
    font-size: .75rem;

    > span {
      display: block;
      padding: .25rem .75rem 0 .75rem;
    }

    & + .frm-error {
      margin-top: -1rem;
      margin-bottom: 1.25rem;
    }
  }

  input[type=text],
  input[type=password] {
    margin: 0 0 .5rem 0;
    padding: .5rem .75rem;

    height: auto;
    line-height: 1;

    // border: 0;
    // border-bottom: 2px solid $ap-border-color;
    // box-shadow: none;
    background-color: transparent;
    &:focus,
    &:active {
      // border: 0;
      // border-bottom: 2px solid $primary-color;
      // box-shadow: none;
      background-color: transparent;
    }
  }
}

.ap-login-form__help {
  display: block;
  margin-top: -1rem;
  font-size: .875rem;

  a {
    color: $ap-inactive-text-color;
    &:hover {
      color: $anchor-color;
    }
  }
}

.ap-login-form__actions {
  margin: 2rem 0 0 0;
  text-align: center;

  > .button {
    margin: 0 auto;
    padding: 1em 1.25em .8rem 1.25rem;
  }
}

.ap-login__footer {
  margin: 3rem 0 0 0;
  padding: 0;
  text-align: center;
  font-size: .875rem;

  > ul {
    display: flex;
    align-items: baseline;
    justify-content: center;

    margin: 0;
    padding: 0;
    list-style-type: none;
    > li {
      margin: 0;
      padding: 0 .75rem;
      // width: 50%;

      &:nth-child(even) {
        // text-align: left;
      }
      &:nth-child(odd) {
        // text-align: right;
      }
      > a {
        color: $ap-subtext-color;
        line-height: 1.2;
        &:hover {
          color: $anchor-color;
        }
      }
    }
  }
}