body.modal-open {
  overflow: hidden;
}

.modal-overlay {
  z-index: 1000;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(240, 240, 240, .75);

  opacity: 0;
  transition: opacity 150ms ease-in;
  pointer-events: none;
  overflow: hidden;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}

.modal {
  position: fixed;
  z-index: 1001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  margin: 0;
  padding: 60px 0;

  width: 70vw;
  min-width: 360px;

  height: 70vh;

  background-color: white;
  border: 1px solid $ap-border-color;
  box-shadow: 0 0 10px $ap-border-color;

  opacity: 0;
  transition: opacity 150ms ease-in;
  pointer-events: none;
  overflow: hidden;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}

.modal-body {
  height: 100%;
  overflow: hidden;
}

.modal-guts {
  /* other stuff we already covered */

  /* cover the modal */
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  padding: 0;
  height: 100%;
  overflow: auto
}

.modal-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 60px;
  border-bottom: 1px solid $ap-border-color;
}

.modal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0 10px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: 60px;
  background-color: #f9f9f9;
}

.modal-alert {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 1.5rem;
  height: 100%;
  text-align: center;
}

/* @group controls */

.modal-button {
  @include disable-mouse-outline;
  white-space: nowrap;

  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: .55rem .75em .4rem .75rem;
  line-height: 16px;
  height: 32px;
  font-family: $button-font-family;

  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: $button-radius;
  transition: $button-transition;

  font-size: map-get($button-sizes, default);
  line-height: 1;
  text-align: center;
  cursor: pointer;

  @include button-style($button-background, $button-background-hover, $button-color);

  & + .modal-button {
    margin-left: 1.5rem;
  }
}

.modal-select-all {
  margin: 0 auto 0 0;
  > input {
    margin: 0 .25rem 0 0;
  }
}

.modal-selected {
  margin: .5rem 0 1rem 0;
  padding: 0;
  list-style-type: none;

  > li {
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    margin: 0 0 .5rem 0;
  }
}

.modal-selected-handle {
  position: relative;
  display: inline-block;
  margin: 0 .75rem 0 0;
  padding: 0;
  // line-height: 1;
  width: 7px;
  height: 7px;
  overflow: hidden;
  // cursor: move;
  background: $black;
  border-radius: 4px;
}

.modal-selected-text {
  // background-color: #f9f9f9;
}

.modal-selected-remove {
  position: relative;
  display: inline-block;
  margin: 0 0 0 .75rem;
  padding: 0;
  // line-height: 1;
  width: 14px;
  height: 14px;
  overflow: hidden;
  cursor: pointer;
  background: $alert-color;
  border-radius: 7px;

  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 60%;
    top: 42%;
    left: 0;
    right: 0;
    margin: auto;
    background: $white;
  }
  &::after {
    // transform: rotate(-45deg);
  }
}

/* @endgroup controls */


/* @group search form */

.modal-search {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

  .modal-search-select {
    margin: 0 .5rem 0 0;
  }

  input.modal-search__text {
    width: 18rem;
    margin: 0 1rem 0 0;
    line-height: 16px;
    font-size: 14px;
    height: 16px + (8px + 8px) + 4px;
  }

/* @endgroup search from */
