.ap-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(small only) {
    margin: 2rem 0 0 0;
  }
  @include breakpoint(medium) {
    margin: 1.5rem 0 0 0;
    font-size: .875rem;
  }

  padding: 0;
  list-style-type: none;

  > li {
    margin: 0 .5rem;
    min-width: 2.5rem;
    text-align: center;

    &.ap-pagination-page {
      @include breakpoint(small only) {
        display: none;
      }
    }

    &.sts-current,
    &.sts-disabled,
    > a {
      padding: .5rem .75rem .5rem .75rem;
      padding-bottom: .5rem - rem-calc(3px);
    }

    &.sts-current {
      background-color: $primary-color;
      border-bottom: 3px solid $primary-color;
      color: $white;
    }
    &.sts-disabled {
      border-bottom: 3px solid $ap-inactive-text-color;
      color: $ap-inactive-text-color;
    }

    &.sts-ellipsis {
      min-width: auto;

      // &:after {
      //   padding: .5rem 0;
      //   content: '\2026';
      // }
    }

    > a {
      display: block;
      margin: 0;
      border-bottom: 3px solid $white;
      color: $white;
      &:hover {
        border-bottom: 3px solid $anchor-color;
        color: $anchor-color;
      }
    }
  }
}