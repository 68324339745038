.ap-feed-container {
  @include xy-grid-container($padding: map-merge($grid-margin-gutters, (small only: 0)));

  @include breakpoint(medium) {
    margin-top: 3rem;
  }
}

.ap-feed {
  @include xy-gutters($gutters: (small only: 0, medium only: 0, large: 30px), $negative: true);

  @include breakpoint(large) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.ap-feed-item {
  display: block;

  @include breakpoint(small only) {
    margin: 0;
    padding: 1.5rem 15px;
    border-top: 1px solid $ap-border-color;

    &:first-child {
      border-top: 0;
    }

    > a {
      display: flex;
    }
  }
  @include breakpoint(medium only) {
    margin: 0;
    padding: 1.75rem 0;
    border-top: 1px solid $ap-border-color;

    > a {
      display: flex;
    }
  }
  @include breakpoint(large) {
    margin: 30px rem-calc(15px) 0 rem-calc(15px);
    width: calc(25% - 30px);
  }
}

.ap-feed-item-body {
  flex: 1;
}

.ap-feed-item__cat {
  display: inline-block;
  line-height: 1.2;
  color: $body-font-color;
  font-weight: normal;

  @include breakpoint(small only) {
    margin: 0 0 .5rem 0;
    font-size: .875rem;
  }
  @include breakpoint(medium only) {
    margin: 0 0 .25rem 0;
    font-size: 1rem;
  }
  @include breakpoint(large) {
    margin: 0 0 .25rem 0;
    font-size: .875rem;
  }
}

.ap-feed-item__title {
  margin: 0 0 .5rem 0;
  font-weight: bold;
  color: $body-font-color;

  @include breakpoint(small only) {
    font-size: 1rem;
  }
  @include breakpoint(medium only) {
    font-size: 1.25rem;
  }
  @include breakpoint(large) {
    font-size: 1.125rem;
  }

  a:hover > & {
    > span {
      background-color: $anchor-color;
      color: $white;
    }
  }
}

.ap-feed-item__description {
  margin: 0;
  padding: 0;
  color: $ap-subtext-color;
  font-size: .875rem;

  @include breakpoint(small only) {
    display: none;
  }
  @include breakpoint(medium only) {
  }
  @include breakpoint(large) {
  }
}

.ap-feed-item__image-container {
  @include breakpoint(small only) {
    flex: 0 0 45%;
    margin: 0 1rem 0 0;
    width: 45%;
  }
  @include breakpoint(medium only) {
    flex: 0 0 30%;
    margin: 0 24px 0 0;
    width: 30%;
  }
}


.ap-feed-item__image {
  position: relative;
  padding: 0 0 56.25% 0;
  width: 100%;
  height: 0;
  overflow: hidden;

  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  @include breakpoint(medium down) {
    margin: 0;
  }
  @include breakpoint(large) {
    margin: 0 0 1.125rem 0;
  }

  > img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;

    background-color: #aaa;
    opacity: .1;
  }

  a:hover > & {
    &:after {
      background-color: #ccc;
    }
  }
}

