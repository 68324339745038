@font-face {
    font-family: 'TT Norms';
    src: local('TT Norms Regular'),
         url('../fonts/TTNorms-Regular.woff') format('woff'),
         url('../fonts/TTNorms-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    src: local('TT Norms Bold'),
         url('../fonts/TTNorms-Bold.woff') format('woff'),
         url('../fonts/TTNorms-Bold.svg') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    src: local('TT Norms ExtraBold'),
         url('../fonts/TTNorms-ExtraBold.woff') format('woff'),
         url('../fonts/TTNorms-ExtraBold.svg') format('svg');
    font-weight: 800;
    font-style: normal;
}


@font-face {
    font-family: 'Noto Sans JP';
    src: local('Noto Sans CJK JP Regular'),
        url('../fonts/NotoSansJP-Regular.woff2') format('woff2'),
        url('../fonts/NotoSansJP-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// @font-face {
//     font-family: 'Noto Sans JP';
//     src: local('Noto Sans CJK JP Medium'),
//         url('../fonts/NotoSansJP-Medium.woff2') format('woff2'),
//         url('../fonts/NotoSansJP-Medium.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
// }

@font-face {
    font-family: 'Noto Sans JP';
    src: local('Noto Sans CJK JP Bold'),
        url('../fonts/NotoSansJP-Bold.woff2') format('woff2'),
        url('../fonts/NotoSansJP-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

// @font-face {
//     font-family: 'Noto Sans JP';
//     src: local('Noto Sans CJK JP Black'),
//         url('../fonts/NotoSansJP-Black.woff2') format('woff2'),
//         url('../fonts/NotoSansJP-Black.woff') format('woff');
//     font-weight: 900;
//     font-style: normal;
// }


@mixin ap-anchor($color: $anchor-color) {
    color: $color;
    &:hover {
      background-color: $secondary-color; //$primary-color;
      color: $white;
    }
}


.ap-header {
  background-color: darken($body-background, 5%);
  background-image: url(../img/coverbg-b.png);
  background-repeat: repeat;

  @include breakpoint(small only) {
    margin: 0 0 1.75rem 0;
    padding: 2.5rem 0 1.5rem 0;
    // border-bottom: 1px solid $ap-border-color;
  }
  @include breakpoint(medium only) {
    margin: 0 0 2rem 0;
    padding: 3rem 0 1.5rem 0;
    // border-bottom: 1px solid $ap-border-color;
  }
  @include breakpoint(large) {
    margin: 0 0 2rem 0;
    padding: 4.5rem 0 1.75rem 0;
    // border-bottom: 1px solid $ap-border-color;
  }
}

.ap-header-content {
  &:not(.narrow) {
    @include xy-grid-container;
  }
  &.narrow {
    @include xy-grid-container($width: 540px);
  }

  @include breakpoint(small only) {
    text-align: center;
  }
  @include breakpoint(medium) {
    display: flex;
    align-items: center;
  }
}

.ap-heading {
  @include breakpoint(small only) {
    margin: 0 1.5rem 0 0;
    padding: 0;

    font-size: 1rem;
    font-weight: 600;
    text-align: center;
  }
  @include breakpoint(medium) {
    margin: 0 1.5rem 0 0;
    padding: 0;
    font-size: 1.125rem;
    font-weight: 600;
    // text-align: center;
  }
}