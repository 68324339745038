.ap-contact-form {
  margin: 0 auto;
  max-width: 480px;

  select,
  input[type=text],
  textarea {
    margin: 0;
  }

  textarea {
    &::-webkit-resizer {
      display: none;
    }
  }
}

.ap-contact-form-row {
  @include breakpoint(small only) {
    margin: 0 0 1.125rem 0;
  }
  @include breakpoint(medium) {
    // display: flex;
    // align-items: flex-start;
    margin: 0 0 1.5rem 0;
  }
}