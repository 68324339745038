// $ap-comment-thumbnail-size: 32px;

.ap-comment {
  position: relative;
  margin: 1.75rem 0 0 0;

  font-size: .875rem;

  &.sts-deleted {
    padding-top: .75rem;
    padding-bottom: .65rem;
    background-color: $ap-layer-2nd-color;

    > p {
      margin: 0;
      font-size: .8rem;
      // font-style: italic;
      color: $medium-gray;
    }
  }

  @include breakpoint(small only) {
    padding: 0 0 0 rem-calc(27px)+.75rem;
    min-height: rem-calc(27px);
    &.sts-reply {
      padding-left: rem-calc(24px)+.5rem;
      min-height: rem-calc(24px);
    }
  }
  @include breakpoint(medium) {
    padding: 0 0 0 rem-calc(34px)+.75rem;
    min-height: rem-calc(34px);
    &.sts-reply {
      padding-left: rem-calc(27px)+.5rem;
      min-height: rem-calc(27px);
    }
  }
}

.ap-comment__header {
  display: flex;
  align-items: center;
}

  .ap-comment__user-thumb-wrap {
    position: absolute;
    left: 0;

    display: block;
    margin: 0;
    padding: 0;
    overflow: hidden;

    @include breakpoint(small only) {
      width: 27px;
      height: 27px;

      &.sts-reply {
        width: 24px;
        height: 24px;
      }
    }
    @include breakpoint(medium) {
      width: 34px;
      height: 34px;

      &.sts-reply {
        width: 27px;
        height: 27px;
      }
    }
  }

  .ap-comment__user-thumb {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;

    @include breakpoint(small only) {
      width: 27px;

      &.sts-reply {
        width: 24px;
      }
    }
    @include breakpoint(medium) {
      width: 34px;

      &.sts-reply {
        width: 27px;
      }
    }
  }

  .ap-comment__user-name {
  }

  .ap-comment__time {
    display: inline;
    margin: 0 0 0 .75rem;
    color: $ap-inactive-text-color;
    font-size: .8rem;
  }

  .ap-comment__body {
    margin: .25rem 0 0 0;
  }

  .ap-comment__actions {
    display: flex;
    align-items: center;
    margin: .65rem 0 0 0;
    padding: 0;
    list-style-type: none;

    font-size: .8rem;

    > li {
      & + li {
        margin: 0 0 0 1.5rem;
      }

      color: $anchor-color;
      cursor: pointer;

      &:hover {
        color: $anchor-color-hover;
        text-decoration: underline;
      }

      &.ap-comment__like {
        display: inline-flex;
        align-items: center;

        margin: 0 0 0 1rem;
        padding: 0;

        // font-size: .935rem;

        &:before {
          content: '';
          display: inline-block;
          margin: 0 .3rem 0 0;
          width: 17px;
          height: 15px;
          background: transparent url(../img/like.svg) no-repeat 0 -30px / 17px 45px;
        }

        // color: $ap-subtext-color;
        color: $white;

        &:not(.sts-disabled) {
          cursor: pointer;

          &.liked {
            &:before {
              background-position: 0 0;
            }
          }

          &:hover {
            color: $anchor-color;
            &:before {
              background-position: 0 -15px;
            }
          }
        }

        > small {
          margin-left: .2rem;
        }
      }

      &.ap-comment__delete {
        margin-left: auto;
      }
    }
  }

    .ap-comment__showreplies {
      display: block;
      > span {
        display: flex;
        align-items: center;
        margin: .65rem 0 0 0;
        padding: 0;
        color: $anchor-color;
        font-size: .8rem;
        font-weight: normal;
        cursor: pointer;

        &:before {
          position: relative;
          top: -2px;
          content: "";
          margin: 0 1rem 0 0;
          width: 6px;
          height: 6px;
          // padding: 3px;
          transform: rotate(45deg);
          border: solid $anchor-color;
          border-width: 0 1px 1px 0;
        }

        &:hover {
          color: $anchor-color-hover;
          text-decoration: underline;
        }
      }

      &.open {
        > span {
          &:before {
            top: 1px;
            transform: rotate(-135deg);
            // translate: 6px;
          }
        }
      }
    }

    .ap-comment__replies__load {
      display: none;

      &.open {
        display: block;
      }
    }


.ap-commentform {
  display: flex;

  &.sts-reply {
    margin: 1.5rem 0 0 0;
  }
}
  .ap-commentform__header {
    display: flex;
    align-items: center;
    overflow: hidden;
    @include breakpoint(small only) {
      flex: 0 0 27px;
      margin: 0 .75rem 0 0;
      width: 27px;
      height: 27px;
      border-radius: 14px;
    }
    @include breakpoint(medium) {
      flex: 0 0 34px;
      margin: 0 .75rem 0 0;
      width: 34px;
      height: 34px;
      border-radius: 17px;
    }
  }
  .ap-commentform__thumb {
    @include breakpoint(small only) {
      width: 27px;
      .sts-reply & {
        width: 24px;
      }
    }
    @include breakpoint(medium) {
      width: 34px;
      .sts-reply & {
        width: 27px;
      }
    }
  }
  .ap-commentform__body {
    flex: 1 0 auto;

    > textarea {
      margin: 0 0 .5rem 0;
      padding: .25rem 0;

      // max-height: 1.5rem;
      min-height: 2.5rem!important;
      line-height: 1.5;

      border: 0;
      border-bottom: 2px solid $ap-border-color;
      box-shadow: none;
      background-color: $ap-commentform-background-color;

      &:focus {
        border-color: lighten($ap-border-color, 15%);
      }
      &::-webkit-resizer {
        display: none;
      }

    }
  }
  .ap-commentform__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // text-align: right;
  }
    .ap-commentform__actions__count {
      margin: 0 auto 0 0;
      font-size: .875rem;
      color: $ap-subtext-color;
    }

    .ap-commentform__actions__cancel {
      display: inline-block;
      margin: 0 1rem 0 0;
      padding: 3px 0 0 0;
      font-size: .8rem;
      cursor: pointer;
      &:hover {
        color: $anchor-color;
      }
    }

  .ap-commentform__errors {
    font-size: .875rem;
    color: $alert-color;
    > span {
      display: block;
    }
  }