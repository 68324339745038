.ap-tabs-nav {
  @include breakpoint(small only) {
    margin: 0 0 .5rem 0;
    font-size: .875rem;
  }
  @include breakpoint(medium) {
    margin: 0 0 .5rem 0;
    font-size: .9375rem;
  }
  border-bottom: 1px solid $ap-border-color;

  > .ap-tabs {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    // font-size: .875rem;

    &.sts-centered {
      justify-content: center;
    }

    > li {
      flex: 0 1 9rem;

      & + li {
        margin-left: 3px;
      }

      > a {
        display: block;
        margin: 0;
        padding: 0 0 .75rem 0;
        // width: 8rem;
        text-align: center;
        color: $dark-gray;
        border-bottom: 5px solid $ap-border-color;

        &:hover {
          color: $anchor-color;
        }

        > span {
          font-size: .875rem;
        }
      }

      > span {
        display: block;
        margin: 0;
        padding: 0 0 .75rem 0;
        // width: 8rem;
        text-align: center;
        color: $ap-inactive-text-color;
        border-bottom: 5px solid $ap-layer-2nd-color;
      }

      &.active {
        > a {
          color: $header-color;
          border-bottom: 5px solid $white;
        }
      }
    }
  }
}