
$ap-border-color: #3C3C5A; //$light-gray;
$ap-layer-pri-border-color: #282840;

// text
$ap-inactive-text-color: $dark-gray;
$ap-username-color: $header-color;
$ap-subtext-color: $helptext-color;
$ap-text-color: #D3D3E6;

// bg
$ap-layer-pri-color: #0e0e1f;
$ap-layer-2nd-color: #1C1C36;

// grid
$gutter-small: 30px;

// topbar
$ap-topbar-height: 60px;
$ap-topbar-background-color: $ap-layer-pri-color;
$ap-topbar-border-color: $ap-layer-pri-border-color;

// footer
$ap-footer-color: $dark-gray;
$ap-footer-background-color: #0e0e1f;

// player
$ap-player-background-color: lighten($body-background, 4%);
$ap-player-bar-color: $ap-border-color;
$ap-player-bar-active-color: $secondary-color;//#0e0e1f;

// comment
$ap-commentform-background-color: $ap-layer-2nd-color;