.ap-collaborators-select {
  margin: 2rem 0 0 0;

  > legend {
    display: block;
    margin: 0;
    padding: 0 0 .5rem 0;
  }
}
  .ap-collaborators-select-container {
    margin: 0;
    padding: 1rem 0 0 0;
    border-top: 1px solid $ap-border-color;
  }
  .ap-collaborators-selection {
  }
  .ap-collaborators-selection__user {
    display: flex;
    align-items: center;
    margin: 0 0 1rem 0;
    padding: 0 0 1rem 0;
    border-bottom: 1px solid $ap-border-color;
  }
  .ap-collaborators-selection__user__picture {
    @include breakpoint(small only) {
      margin: 0 .5rem 0 0;
      width: 27px;
    }
    @include breakpoint(medium) {
      margin: 0 .5rem 0 0;
      width: 30px;
    }
  }
  .ap-collaborators-selection__user__name {
    font-size: .875rem;
  }
  .ap-collaborators-selection__user__delete {
    margin: 0 0 0 auto;

    color: $anchor-color;
    font-size: .8rem;
    cursor: pointer;
  }

.ap-users-to-collabrate {
  margin: 0;
  padding: 0 0 5px 0;
  list-style-type: none;

  > li {
    cursor: pointer;
    display: flex;
    align-items: center;

    display: block;
    margin: 0 auto 0 0;
    padding: .75rem 1rem;
    border-top: 1px solid $ap-border-color;
    &:first-child {
      border-top: 0;
    }
    &:hover {
      background-color: #f9f9f9;
    }

    > input {
      margin: 0 .5rem 0 0;
    }
  }
}
