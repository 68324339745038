.ap-home-header {
  background-color: darken($body-background, 2%);
  background-image: url(../img/coverbg-b.png);
  background-repeat: repeat;

  @include breakpoint(small only) {
    margin: 0 0 1rem 0;
    padding: 1.75rem 0;
    // border-bottom: 1px solid $ap-border-color;
    text-align: center;
  }
  @include breakpoint(medium only) {
    margin: 0 0 .75rem 0;
    padding: 2rem 0 2rem 0;
    // border-bottom: 1px solid $ap-border-color;
  }
  @include breakpoint(large) {
    margin: 0 0 1rem 0;
    padding: 2rem 0 2rem 0;
    // border-bottom: 1px solid $ap-border-color;
  }
}

.ap-home-header-content {
  @include xy-grid-container;
  @include breakpoint(small only) {
  }
  @include breakpoint(medium) {
    display: flex;
    align-items: center;
  }
}

.ap-home-heading {
  @include breakpoint(small only) {
    display: inline-block;
    margin: 0;
    padding: .5rem .875rem;
    font-size: .875rem;
    font-weight: normal;
    // text-align: center;
    color: $ap-inactive-text-color;
    border: 1px solid $ap-inactive-text-color;
    border-radius: 3px;

    > small {
      margin: 0 0 0 .5rem;
      font-size: .875rem;
      font-weight: normal;
    }
  }
  @include breakpoint(medium) {
    margin: 0 1.5rem 0 0;
    padding: .5rem .875rem;
    font-size: .875rem;
    font-weight: normal;
    // text-align: center;
    color: $ap-inactive-text-color;
    border: 1px solid $ap-inactive-text-color;
    border-radius: 3px;

    > small {
      margin: 0 0 0 .75rem;
      font-size: .875rem;
      font-weight: normal;
    }
  }
}

.ap-home-sub-heading {
  @include breakpoint(small only) {
    margin: 0;
    padding: 0;

    font-size: 1rem;
    font-weight: bold;
    text-align: center;

    > small {
      margin: 0 0 0 .5rem;
      font-size: .875rem;
      font-weight: normal;
    }
  }
  @include breakpoint(medium) {
    margin: 0 1.5rem 0 0;
    padding: 0 0 1.25rem 0;
    font-size: 1rem;
    font-weight: normal;
    border-bottom: 1px solid $ap-border-color;
  }
}


.ap-home-nofollowing {
  background-color: darken($body-background, 2%);
  background-image: url(../img/coverbg-b.png);
  background-repeat: repeat;

  @include breakpoint(small only) {
    margin: 0 0 1.5rem 0;
    padding: 1.75rem 15px;
    text-align: center;
  }
  @include breakpoint(medium only) {
    margin: 0 0 2rem 0;
    padding: 2rem 0 2rem 0;
  }
  @include breakpoint(large) {
    margin: 0 0 2rem 0;
    padding: 2rem 0 2rem 0;
  }

  text-align: center;

  > p {
    margin: 0;
    font-size: .875rem;
    > a {
      margin: 0 .25rem;
      font-size: 1.125rem;
    }
  }
}
.ap-home-nofollowing__message {
  display: inline-block;
  margin: 0 0 .75rem 0;
  font-size: 1.125rem;
  font-weight: normal;
}