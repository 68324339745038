.ap-credit-fieldset__description {
  font-size: .875rem;
  > b {
    font-size: 1rem;
    font-weight: normal;
  }
}

.ap-credit-form {
  padding: 0 0 1rem 0;
}

.ap-credit-form-header {
  display: flex;
  align-items: baseline;
  padding: 1rem 0 .75rem 0;
  border-top: 1px solid $ap-border-color;
}
  .ap-credit-form-header__no {
    margin: 0 auto 0 0;
    font-size: 1.125rem;
    font-weight: normal;

    &:before {
      content: "#";
    }
  }
  .ap-credit-form-header__delete {
    color: $anchor-color;
    font-size: .8rem;
    cursor: pointer;
    &:hover {
      color: $anchor-color-hover;
    }
  }
