.ap-footer {
  color: $ap-footer-color;
  background-color: $ap-footer-background-color;

  @include breakpoint(small only) {
    margin: 3rem 0 0 0;
    padding: 3rem 0 3rem 0;
  }
  @include breakpoint(medium) {
    margin: 4rem 0 0 0;
    padding: 2rem 0 rem-calc(75px)+4rem 0;
  }
}

.ap-footer__content {
  @include xy-grid-container($padding: map-merge($grid-margin-gutters, (small only: 0)));
}

.ap-footer__nav {
  @include breakpoint(small only) {
    > ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      font-size: .875rem;

      > li {
        margin: 0;
        padding: .25rem 15px;

        > a {
          color: inherit;
          // &:hover {
          //   color: $anchor-color;
          // }
        }
      }
    }
  }
  @include breakpoint(medium) {
    display: flex;
    font-size: .875rem;

    > ul {
      display: flex;
      margin: 0 auto 0 0;
      padding: 0;
      list-style-type: none;

      > li {
        & + li {
          margin-left: 1.5rem;
        }

        > a {
          color: inherit;
          &:hover {
            color: $anchor-color;
          }
        }
      }
    }
  }
}

.ap-jasrac-consent {
  font-size: .8rem;

  @include breakpoint(small only) {
    display: block;
    margin: 1rem 15px 0 15px;
  }
  @include breakpoint(medium) {
  }
}

.ap-follow-us {
  @include breakpoint(small only) {
    margin: 3rem 0 0 0;
  }
  @include breakpoint(medium) {
    margin: 4rem 0 0 0;
  }

  > ul {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0;
    padding: 0;
    list-style-type: none;
    > li {
      margin: 0 1rem;
      width: 50px;
    }
  }
}