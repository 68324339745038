/* @group cover */

.ap-cover {
  background-color: lighten($body-background, 1%);
  background-image: url(../img/coverbg.png);
  background-repeat: repeat;

  @include breakpoint(small only) {
    padding: 1.5rem 0;
  }
  @include breakpoint(medium only) {
    margin: 0 0 1rem 0;
  }
  @include breakpoint(large) {
    margin: 0 0 1rem 0;
  }
}

.ap-cover-content {
  @include xy-grid-container;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include breakpoint(small only) {
    min-height: 270px;
  }
  @include breakpoint(medium only) {
    min-height: 360px;
  }
  @include breakpoint(large) {
    height: 420px;
  }
}

.ap-cover__heading {
  display: block;
  margin: 0;
  padding: 0;

  @include breakpoint(small only) {
  }
  @include breakpoint(medium only) {
    max-width: 540px;
    width: 100%;
  }
  @include breakpoint(large) {
    max-width: 600px;
    width: 100%;
  }

  > img {
    display: block;
    width: 100%;
    max-width: 640px;
  }
}

.ap-cover__description {
  @include breakpoint(small only) {
    margin: 1rem 0 0 0;
    font-size: 1rem;
  }
  @include breakpoint(medium only) {
    margin: 1rem 0 0 0;
    font-size: 1.125rem;
  }
  @include breakpoint(large) {
    margin: 1.5rem 0 0 0;
    font-size: 1.25rem;
  }
}

/* @end group cover */

.ap-index-container {
  @include xy-grid-container($padding: map-merge($grid-margin-gutters, (small only: 0)));
}

.ap-index-header {
  @include breakpoint(small only) {
    margin: 2.5rem 0 0 0;
    padding: 0 15px 1.75rem 15px;
    border-bottom: 1px solid $ap-border-color;
    text-align: center;
  }
  @include breakpoint(medium only) {
    display: flex;
    align-items: center;

    margin: 2.5rem 0 0 0;
    padding: 0 0 1.5rem 0;
    border-bottom: 1px solid $ap-border-color;
  }
  @include breakpoint(large) {
    display: flex;
    align-items: center;

    margin: 4.5rem 0 0 0;
    padding: 0 0 1.5rem 0;
    border-bottom: 1px solid $ap-border-color;
  }
}

.ap-index-heading {
  @include breakpoint(small only) {
    margin: 0;
    padding: 0;

    font-size: 1rem;
    font-weight: 600;
    text-align: center;

    > small {
      margin: 0 0 0 .5rem;
      font-size: .875rem;
      font-weight: normal;
    }
  }
  @include breakpoint(medium) {
    margin: 0 1.5rem 0 0;
    padding: 0;
    font-size: 1rem;
    font-weight: normal;
    // text-align: center;

    > small {
      margin: 0 0 0 .75rem;
      font-size: .875rem;
      font-weight: normal;
    }
  }
}
